import z from 'zod';

const envSchema = z.object({
  BUILD_ID: z.string().optional(),
  BUILD_NUMBER: z.string().optional(),
  PR_BRANCH: z.string().optional(),
  COMMIT_ID: z.string().optional(),
  PORT: z.string().default('3000'),
  NODE_ENV: z
    .enum(['development', 'production', 'test'])
    .default('development')
    .optional(),
  AUTH_AUTHORITY: z.string(),
  AUTH_CLIENT: z.string(),
  AUTH_SCOPE: z.string(),
  AUTH_REDIRECT: z.string(),
  API_URL: z.string(),
  SUBSCRIPTION_KEY: z.string(),
  APPLICATIONINSIGHTS_CONNECTION_STRING: z.string(),
});

export type Env = z.infer<typeof envSchema>;

/** Remove the VITE_ prefix from environment variables. */
export function removePrefix(env: object): Env {
  return Object.keys(env).reduce((accum, key) => {
    return { ...accum, [key.replace('VITE_', '')]: env[key] };
  }, {}) as Env;
}

export default envSchema;
