import React, { startTransition, useRef } from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { NotificationType } from '#ui/constants';
import { useSnapshot } from 'valtio';
import { proxyMap } from 'valtio/utils';
import { LinkButtonProps } from '../Button';
import { ToastElement } from './Toast';

const state = proxyMap<
  string,
  ToastNotificationProps & { status: ToastNotificationProps['type'] }
>();

export const showToast = (toast: ToastNotificationProps) => {
  startTransition(() => {
    state.set(toast.key || String(Date.now()), {
      ...toast,
      status: toast.type,
    });
  });
};

export const removeToast = (key: string) => {
  state.delete(key);
};

export type ToastNotificationProps = {
  key?: string;
  link?: LinkButtonProps;
  headline: string;
  message?: string;
  date?: string;
  type: NotificationType;
  size?: 'short' | 'long';
  closable?: boolean;
};

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const viewportRef = useRef(null);
  const toasts = useSnapshot(state);

  return (
    <>
      <ToastPrimitive.Provider>
        {Array.from(toasts).map(([key, toast]) => {
          return <ToastElement key={key} toast={toast} toastKey={key} />;
        })}
        <ToastPrimitive.Viewport
          data-toast-list=""
          className="toast-viewport"
          ref={viewportRef}
        />
      </ToastPrimitive.Provider>
      {children}
    </>
  );
}
