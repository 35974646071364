import { clear, entries } from 'idb-keyval';
import { proxy, subscribe } from 'valtio';
import { z } from 'zod';
import { ROLES } from '@/api/auth/permissions';
import queryClient, { prefix } from '@/api/queryClient';

const isIndexedDBSupported = 'indexedDB' in globalThis;

// The key to use for storing the state in localStorage
const stateKey = `global-state-${import.meta.env.DEV ? 'dev' : __BUILD_ID__}`;

const stateSchema = z.object({
  /** Boolean determining if a valid query cache exists */
  hasCache: z.boolean(),
  /** Controls whether or not the devtools are enabled */
  devtoolsEnabled: z.boolean(),
  devtoolsShowAppInfo: z.boolean().optional(),
  /** Requires devtoolsEnabled; Role to override the current user's role for development and testing purposes */
  testingRole: z.nativeEnum(ROLES).optional(),
  highlightedText: z.string().optional(),
});

export type State = z.infer<typeof stateSchema>;

const cached =
  import.meta.env.MODE === 'test'
    ? null
    : // eslint-disable-next-line
      globalThis?.localStorage?.getItem(stateKey);

const initial: State = {
  hasCache: false,
  devtoolsEnabled: import.meta.env.DEV && import.meta.env.MODE !== 'test',
  devtoolsShowAppInfo: false,
  testingRole: ROLES.projectParticipant,
  highlightedText: undefined,
};

const initialState = stateSchema.parse(
  cached ? JSON.parse(cached) || initial : initial,
);

const state = proxy<State>(initialState);

if (import.meta.env.MODE !== 'test') {
  subscribe(state, () => {
    globalThis.requestIdleCallback(
      () => {
        globalThis.localStorage.setItem(stateKey, JSON.stringify(state));
      },
      { timeout: 1000 },
    );
  });
}

export async function clearCache() {
  state.hasCache = false;
  queryClient.clear();
  clear();
}

async function checkQueryCacheValidity(): Promise<boolean> {
  if (state.hasCache) return true; // If the state already has a cached value, return true
  const values = await entries(); // get all entries from the IndexedDB database
  if (values.length === 0) return false; // If there are no entries, return false
  return values.some(([key, val]) => {
    // Check if there is a cached value that matches specific conditions (buster + prefix)
    return (
      key.toString().startsWith(prefix) &&
      val?.buster === (import.meta.env.DEV ? 'dev' : __BUILD_ID__)
    );
  });
}

/**
 * Resolves the cache status by checking it and updates the corresponding state.
 * If applicable, it also resolves other asynchronous state before updating the cache status.
 * @returns {Promise<void>} A promise that resolves once the cache status is resolved and the state is updated.
 */
export async function resolveQueryCacheStatus(): Promise<void> {
  if (!isIndexedDBSupported) return; // If indexedDB isn't supported, return
  try {
    // Update the state with the current cache status
    state.hasCache = await checkQueryCacheValidity();
  } catch (error) {
    console.error('Failed to update state', error);
  }
}

/** Toggles the devtoolsEnabled state */
export function toggleDevtools() {
  state.devtoolsEnabled = !state.devtoolsEnabled;
}

if (import.meta.env.MODE !== 'mock') {
  Object.defineProperty(globalThis, 'devtoolsEnabled', {
    get: () => state.devtoolsEnabled,
    set: (value) => {
      state.devtoolsEnabled = value;
    },
  });

  window.toggleDevtools = toggleDevtools;
  window.clearCache = clearCache;
}
export default state;
