import {
  showToast,
  ToastNotificationProps,
} from '@kk/ui/components/Notifications/ToastProvider';
import i18n from '@kk/ui/i18n';
import state from '@/state';
import { ErrorResponse, isErrorResponse } from './api-types';
import { loginRequest } from './auth/auth.config';
import msalInstance from './auth/msalInstance';

/**
 * Represents an error related to missing permissions.
 */
export class PermissionError extends Error {
  constructor(id: string, ...args) {
    super(`Missing permission: "${id}"`, ...args);
  }
}

/**
 * Displays an error toast notification for API errors.
 *
 * @param {Omit<ToastNotificationProps, 'type' | 'headline'>} [toast] - Optional toast configuration, excluding 'type' and 'headline'.
 */
function showAPIErrorToast(
  toast?: Omit<ToastNotificationProps, 'type' | 'headline'>,
) {
  showToast({
    type: 'error',
    headline: i18n.t('error.api.title'),
    ...toast,
  });
}

/**
 * Displays a validation error toast notification.
 *
 * @param {Omit<ToastNotificationProps, 'type' | 'headline'>} [toast] - Optional additional properties to pass to the toast notification.
 */
function showValidationErrorToast(
  toast?: Omit<ToastNotificationProps, 'type' | 'headline'>,
) {
  showToast({
    type: 'error',
    headline: i18n.t('error.validation'),
    message: '',
    ...toast,
  });
}

/**
 * Handles error notifications for API responses.
 *
 * @param {unknown} error - The error object that was thrown.
 * @param {string} [key] - An optional key to identify the notification.
 */
export async function handleErrorNotification(
  error?: ErrorResponse,
  key?: string,
) {
  const operationId = error ? getOperationId(error) : undefined;
  const operationIdString = operationId ? ` (OperationId: ${operationId})` : '';
  if (isErrorResponse(error)) {
    let message = (await error.text?.()) || error.data;
    switch (error.status) {
      case 409:
        if (typeof message === 'string') {
          message = message.concat(operationIdString);
          showValidationErrorToast({ message, key });
          break;
        } else {
          showAPIErrorToast({
            key,
            message: i18n.t('error.api.message') + operationIdString,
          });
        }
        break;
      default:
        showAPIErrorToast({
          key,
          message: i18n.t('error.api.message') + operationIdString,
        });
        break;
    }
  }
}

/**
 * Handles token errors by clearing the cache and initiating a logout redirect.
 * @param {unknown} error - The error to be handled.
 */
export function handleTokenError(error: unknown) {
  msalInstance.clearCache();
  msalInstance.loginRedirect(loginRequest);
}

export function getOperationId(error: unknown): string | undefined {
  if (error instanceof Error && error.cause) {
    const operationIds = (error.cause as { operationIds: string[] })
      .operationIds;
    if (operationIds.length) {
      return operationIds.join(', ');
    }
  }
  if (isErrorResponse(error)) {
    return error.headers.get('Operationid') ?? undefined;
  }
}

export function assertNoLoaderErrors<T extends [...(Error | T[number])[]]>(
  id: string,
  data: T,
  ...args
): asserts data is { [K in keyof T]: Exclude<T[K], Error> } {
  if (
    (Array.isArray(data) ? data : [data]).findIndex(
      (value) => value instanceof Error,
    ) !== -1
  ) {
    throw new LoaderError(id, data, ...args);
  }
}

export class LoaderError extends Error {
  constructor(id: string, responses: Array<Error | unknown>, ...args) {
    if (state.devtoolsEnabled) console.error({ id, responses });
    const formattedErrors = responses
      .map((response, index) => {
        if (response instanceof Error) {
          return `#${index + 1} - ${response.message}`;
        }
        return null;
      })
      .filter(Boolean);

    const operationIds = responses
      .map((response) => getOperationId(response))
      .filter(Boolean);

    const errorMessage = `Failed to load ${id} data: \n\n${formattedErrors.join(
      '\n',
    )}`;

    super(errorMessage, ...args, { cause: { operationIds } });
  }
}
