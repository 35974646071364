import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import Spinner from '../Spinner';
import { buttonThemes, defaultBtnClasses, Theme } from './Button';
import { ButtonProps } from './Test.types';

export type LinkButtonProps = Omit<ButtonProps, 'theme'> & {
  to?: string;
  preload?: number;
  href?: string;
  subtle?: boolean;
  padding?: string;
  theme?: Theme;
};

const defaultLinkClasses = clsx(
  'flex cursor-pointer items-center rounded border-none w-content',
  'hover:text-blue-40  hover:underline active:text-blue-110 ',
);

export function LinkButton({
  label,
  compact,
  rightAligned,
  icon,
  iconProps,
  loading,
  to,
  href,
  onClick,
  className,
  subtle,
  padding,
  theme,
  ...rest
}: LinkButtonProps) {
  const classes = theme
    ? clsx(
        'flex items-center rounded border',
        defaultBtnClasses,
        buttonThemes[theme],
        className,
      )
    : clsx(
        defaultLinkClasses,
        subtle
          ? 'text-neutral-110 active:text-blue-110 active:no-underline'
          : 'font-medium text-blue-60 ',
        'disabled:text-neutral-60 aria-disabled:text-neutral-60',
        padding !== undefined
          ? padding
          : compact
            ? [
                'typ-link-xs',
                rightAligned
                  ? { 'pr-1 pl-2': icon && !loading }
                  : { 'pr-2 pl-1': icon && !loading },
              ]
            : [
                'typ-link',
                rightAligned
                  ? { 'pr-2 pl-4': icon && !loading }
                  : { 'pr-4 pl-2': icon && !loading },
              ],
        rightAligned && 'flex-row-reverse',
        className,
      );

  const buttonContent = loading ? (
    <Spinner
      className={clsx(
        'flex items-center justify-center',
        compact ? 'px-4' : 'px-1',
      )}
      size={compact ? 'small' : 'medium'}
    />
  ) : (
    <>
      {icon ? (
        <Icon
          size={compact || subtle ? 'tiny' : 'small'}
          {...(iconProps ? iconProps : {})}
          type={icon}
          className={clsx(
            'flex-none self-center',
            rightAligned
              ? compact
                ? 'ml-2'
                : 'laane:ml-4 kunde:mr-2'
              : compact
                ? 'mr-2'
                : 'laane:mr-4 kunde:mr-2',
            iconProps?.className,
          )}
        />
      ) : null}
      <span className="break-all">{label}</span>
    </>
  );

  if (href) {
    return (
      <a
        href={href}
        className={clsx(classes, { 'pointer-events-none': rest.disabled })}
        rel="nofollow"
        {...rest}
        tabIndex={rest.disabled ? -1 : undefined}
        aria-disabled={rest.disabled ? 'true' : undefined}
      >
        {buttonContent}
      </a>
    );
  }

  if (to) {
    return (
      <Link
        to={to}
        className={clsx(classes, { 'pointer-events-none': rest.disabled })}
        {...rest}
        tabIndex={rest.disabled ? -1 : undefined}
        aria-disabled={rest.disabled ? 'true' : undefined}
      >
        {buttonContent}
      </Link>
    );
  }

  return (
    <button onClick={onClick} className={classes} {...rest}>
      {buttonContent}
    </button>
  );
}

export default LinkButton;
