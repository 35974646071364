/**
 * Loan Api
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from '@oazapfts/runtime';
import * as QS from '@oazapfts/runtime/query';
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
  headers: {},
  baseUrl: '/',
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type AllocationContract = {
  contractDraftId?: string | null;
  inLAS?: boolean;
  principalAmount?: number;
  maturityYears?: number;
  startDate?: string;
  endDate?: string;
  contractLoanType?: ContractLoanTypeEnum;
  guarantorMaxPrincipalAmount?: number;
  guarantorMaxMaturity?: number;
  guarantorContractLoanTypeOnly?: ContractLoanTypeEnum;
  guarantorLastChangedTime?: string | null;
  customerMaxPrincipalAmount?: number;
  customerMaxMaturity?: number;
  customerContractLoanTypeOnly?: ContractLoanTypeEnum;
  customerLastChangedTime?: string | null;
  projectName?: string | null;
  industryCode?: string | null;
  industryCodeLastChangedTime?: string | null;
  paymentAdviseType?: PaymentAdviseTypes;
  eanId?: string | null;
  paymentAdviseChangeTime?: string | null;
};
export type AllocationResponse = {
  allocationId?: number;
  allocationName?: string | null;
  companyId?: string | null;
  originalAmount?: number | null;
  remainingAmount?: number | null;
  remainingAmountAdjusted?: number | null;
  maxTimeToMaturity?: number;
  approveDate?: string;
  createDate?: string;
  updateDate?: string | null;
  allocationContracts?: AllocationContract[] | null;
};
export type AllocationRequest = {
  allocationName?: string | null;
  companyId: string;
  originalAmount?: number | null;
  maxTimeToMaturity?: number | null;
  activeUntil?: string | null;
  allocationStatus?: number | null;
};
export type ContractDraftRequest = {
  loanPurpose?: string | null;
  maxPrincipal?: number | null;
  maxMaturity?: number | null;
  productType?: ContractLoanTypeEnum;
};
export type GuarantorListEntry = {
  companyId?: string | null;
  liabilityPercentage?: number | null;
};
export type ContractDraftReleationsResponse = {
  isProRata?: boolean;
  guarantorList?: GuarantorListEntry[] | null;
  updateDate?: string;
};
export type GuarantorResponse = {
  guarantorId?: string | null;
  guarantorName?: string | null;
};
export type GuaranteeRelationRequest = {
  guaranteeType?: GuaranteeType;
  isProRata?: boolean;
  guarantorList?: GuarantorListEntry[] | null;
};
export type EanInformation = {
  eanId?: string | null;
  comment?: string | null;
};
export type PaymentAdviseRequest = {
  paymentAdviseType?: PaymentAdviseTypes;
  eanId?: string | null;
};
export type ContractDraftGuaranteeInformationRequest = {
  maxPrincipal?: number | null;
  maxMaturity?: number | null;
  productType?: string | null;
};
export type LoanSearchResponse = {
  contractId?: string | null;
  companyId?: string | null;
  companyLasId?: string | null;
  principalAmount?: number | null;
  startDate?: string;
  loanPurpose?: string | null;
  notionalAmount?: number;
  maturityDate?: string;
  loantype?: ContractLoanTypeEnum;
  greenLoan?: boolean;
  loanStatus?: ContractStatus;
};
export type ValidationErrorResponse = {
  validationErrors?: string[] | null;
};
export type ErrorResponse = {
  errorMessage?: string[] | null;
};
export type LoanSearchRelationResponse = {
  contractId?: string | null;
  companyLasId?: string | null;
  relationCompanyLasId?: string | null;
  relationCompanyId?: string | null;
  relationType?: string | null;
  liabilityPercentage?: number | null;
  liabilityAmount?: number | null;
};
export type HolidayResponse = {
  startDate?: string;
  endDate?: string;
  holidayDates: string[] | null;
};
export type InterestSheetCalculation = {
  contractLoanType: ContractLoanTypeEnum;
  amortizationProfile: AmortizationProfile;
  paymentFreq: PaymentFreqEnum;
  yearsToMaturity: number;
  yearsWithFixedRate: number;
  interest: number;
  spread: number | null;
};
export type InterestSheetPricing = {
  contractLoanType: ContractLoanTypeEnum;
  interestConvension: InterestConvensionEnum;
  paymentFreq: PaymentFreqEnum;
  interestRate: number;
  kkSpread?: number | null;
  isLeasing?: boolean;
};
export type InterestSheetResponse = {
  interestSheetCalculations: InterestSheetCalculation[] | null;
  interestSheetPricings: InterestSheetPricing[] | null;
  baseCalculationTime: string;
  calculationTime: string;
};
export type PriceSheetResponse = {
  contractLoanType: ContractLoanTypeEnum;
  amortizationProfile: AmortizationProfile;
  isLeasing: boolean;
  yearsToMaturity: number;
  yearsWithFixedRate: number;
  paymentFreq: PaymentFreqEnum;
  spread: number | null;
  interest: number | null;
  isCheapest?: boolean;
};
export type AmortRecord = {
  paymentDate?: string;
  dueDate?: string;
  principal?: number;
  repayment?: number;
  interestPayment?: number;
  marginPayment?: number;
  extraOrdinaryRepayment?: number;
  payment?: number;
  remainingDebt?: number;
  notifiedDate?: string | null;
  knownInterest?: boolean;
};
export type TimeMeasure = {
  years?: number;
  months?: number;
  days?: number;
};
export type LoanInfo = {
  contractId?: string | null;
  companyId?: string | null;
  companyLasId?: string | null;
  principalAmount?: number;
  startDate?: string;
  loanPurpose?: string | null;
  industryCode?: string | null;
  notionalAmount?: number;
  maturityDate?: string;
  loanProduct?: ContractLoanTypeEnum;
  sustainableFramework?: SustainableFrameworkType;
  greenLoan?: boolean;
  currency?: CurrencyCode;
  redemptionValue?: number;
  interestRate?: number;
  nextFixingDate?: string | null;
  latestFixingDate?: string | null;
  interestPaymentFrequency?: PaymentFreqEnum;
  spread?: number;
  fundingSpread?: number | null;
  agreementDate?: string;
  interestConditionExpiryDate?: string;
  firstInterestDate?: string | null;
  lastInterestDate?: string | null;
  firstInterestDueDate?: string | null;
  lastInterestDueDate?: string | null;
  amortRecordList?: AmortRecord[] | null;
  timeToMaturity?: TimeMeasure;
  accountingAreaId?: string | null;
  accoutingGroupId?: string | null;
  instrumentId?: string | null;
  customerSwap?: string | null;
  firstPayment?: number;
  loanDuration?: TimeMeasure;
  marginRate?: number;
  numberOfPayments?: number;
  interestRatePlusSpread?: number | null;
};
export type PortfolioInfo = LoanInfo & {
  loanCategory?: LoanCategory;
  nextValueDate?: string | null;
  nonAccruedInterest?: number | null;
  disposableAmount?: number | null;
  interestType?: InterestType;
  bossInfoId?: string | null;
  bossInfoProjectName?: string | null;
  swaps?: string | null;
  swapRelatedContractId?: string | null;
  projectName?: string | null;
  relation?: Relation;
  legalName?: string | null;
  asOfDate?: string | null;
};
export type PortfolioInfoResponse = {
  asOfDate?: string | null;
  ownerPortfolio?: PortfolioInfo[] | null;
  guaranteePortfolio?: PortfolioInfo[] | null;
  partnershipLiabilityPortfolio?: PortfolioInfo[] | null;
  billReceiverPortfolio?: PortfolioInfo[] | null;
};
export type EligibleLoanResponse = {
  contractId?: string | null;
  loanProduct?: ContractLoanTypeEnum;
  sustainableFrameWork?: SustainableFrameworkType;
  notionalAmount?: number;
};
export type AmortListResponse = {
  valueDate?: string;
  repayment?: number | null;
  interest?: number | null;
  margin?: number | null;
  additionalPayment?: number | null;
  payment?: number | null;
  notionalAmount?: number | null;
  notifiedDate?: string | null;
  knownInterest?: boolean;
  interestRateStatus?: InterestRateStatus;
};
export type LoanInfoResponse = {
  contractId?: string | null;
  companyId?: string | null;
  companyLasId?: string | null;
  principalAmount?: number;
  startDate?: string;
  loanPurpose?: string | null;
  industryCode?: string | null;
  notionalAmount?: number;
  maturityDate?: string;
  loanProduct?: ContractLoanTypeEnum;
  sustainableFramework?: SustainableFrameworkType;
  greenLoan?: boolean;
  currency?: CurrencyCode;
  redemptionValue?: number;
  interestRate?: number;
  nextFixingDate?: string | null;
  latestFixingDate?: string | null;
  interestPaymentFrequency?: PaymentFreqEnum;
  spread?: number;
  agreementDate?: string;
  interestConditionExpiryDate?: string | null;
  amortList?: AmortListResponse[] | null;
  timeToMaturity?: TimeMeasure;
  accountingAreaId?: string | null;
  accountingGroupId?: string | null;
  instrumentId?: string | null;
  customerSwap?: string | null;
  marginRate?: number;
  interestRatePlusSpread?: number;
  firstPayment?: number;
  loanDuration?: TimeMeasure;
};
export type CalculationInfoResponse = LoanInfoResponse & {
  eventDescription?: EventDescriptionCode;
  repayAmount?: number;
  eventDueDate?: string | null;
  eventValueDate?: string | null;
  changeMethod?: ChangeMethodCode;
  calculationDateTime?: string;
  notionalAmountOnValueDate?: number;
  accruedInterest?: number;
  compensationAmount?: number;
  compensationAmountCalculated?: number;
  compensationAmountReported?: number | null;
  preEventMaturityDate?: string;
  postEventMaturityDate?: string;
  paymentReduction?: number;
  isOnTerm?: boolean;
  timeToMaturityReduction?: TimeMeasure;
  markedDataDateTime?: string;
  firstInterestDate?: string | null;
  lastInterestDate?: string | null;
  firstInstalmentFreeRepaymentDate?: string | null;
  calculationPath?: string | null;
  initialCosts?: number;
  amortizationProfile?: AmortizationProfile;
  fixedInterestYears?: number | null;
  fixedInterestPeriodType?: FixedInterestPeriodType;
  leasingFee?: number | null;
  scrap?: number | null;
  numberOfPayments?: number;
  fixedFirstRepayment?: number | null;
  averageDuration?: number | null;
  installmentFreeTerms?: number | null;
};
export type BasicLoanInfoResponse = {
  contractId?: string | null;
  companyId?: string | null;
  companyLasId?: string | null;
  startDate?: string;
  maturityDate?: string;
  loanProduct?: ContractLoanTypeEnum;
  principalAmount?: number;
  notionalAmount?: number;
  currency?: CurrencyCode;
  agreementDate?: string;
  timeToMaturity?: TimeMeasure;
  loanPurpose?: string | null;
  industryCode?: string | null;
  interestRate?: number;
  interestPaymentFrequency?: PaymentFreqEnum;
  redemptionValue?: number;
  amortizationProfile?: AmortizationProfile;
  nextFixingDateString?: string | null;
};
export type PostingListResponse = {
  notifiedDate?: string | null;
  valueDate?: string;
  paymentTypeString?: string | null;
  paymentAmount?: number;
  creditAmount?: number;
};
export type CreditInfoResponse = BasicLoanInfoResponse & {
  disposableAmount?: number;
  availableAmount?: number;
  accruedInterest?: number;
  accruedPeriodStartDate?: string;
  accrudPeriodEndDate?: string;
  postings?: PostingListResponse[] | null;
  marginRate?: number;
  initialCosts?: number;
  firstInterestDate?: string;
  loanDuration?: TimeMeasure;
};
export type FirstInterestPaymentDateResponse = {
  validDates?: string[] | null;
};
export type TermDate = {
  dueDate?: string;
  remainingDebt?: number | null;
  notifiedDate?: string | null;
};
export type InTermDate = TermDate & {
  valueDate?: string;
};
export type OutOfTermDate = TermDate;
export type PartialRedemptionTermDateResponse = {
  lastNotifiedPaymentDate?: string | null;
  inTermDates?: InTermDate[] | null;
  outOfTermDates?: OutOfTermDate[] | null;
};
export type RedemptionStatusResponse = {
  data2LASID?: number;
  status?: RedemptionStatusEnum;
  message?: string | null;
  kreditAdvis?: number[] | null;
};
export type CalculationInfo = {
  contractId?: string | null;
  companyId?: string | null;
  companyLasId?: string | null;
  principalAmount?: number;
  startDate?: string;
  loanPurpose?: string | null;
  industryCode?: string | null;
  notionalAmount?: number;
  maturityDate?: string;
  loanProduct?: ContractLoanTypeEnum;
  sustainableFramework?: SustainableFrameworkType;
  greenLoan?: boolean;
  currency?: CurrencyCode;
  redemptionValue?: number;
  interestRate?: number;
  nextFixingDate?: string | null;
  latestFixingDate?: string | null;
  interestPaymentFrequency?: PaymentFreqEnum;
  spread?: number;
  fundingSpread?: number | null;
  agreementDate?: string;
  interestConditionExpiryDate?: string;
  firstInterestDate?: string | null;
  lastInterestDate?: string | null;
  firstInterestDueDate?: string | null;
  lastInterestDueDate?: string | null;
  amortRecordList?: AmortRecord[] | null;
  timeToMaturity?: TimeMeasure;
  accountingAreaId?: string | null;
  accoutingGroupId?: string | null;
  instrumentId?: string | null;
  customerSwap?: string | null;
  firstPayment?: number;
  loanDuration?: TimeMeasure;
  marginRate?: number;
  numberOfPayments?: number;
  interestRatePlusSpread?: number | null;
  eventDescription?: EventDescriptionCode;
  repayAmount?: number;
  eventDueDate?: string | null;
  eventValueDate?: string | null;
  changeMethod?: ChangeMethodCode;
  calculationDateTime?: string;
  notionalAmountOnValueDate?: number;
  accruedInterest?: number;
  compensationAmount?: number;
  compensationAmountCalculated?: number;
  compensationAmountReported?: number | null;
  preEventMaturityDate?: string;
  postEventMaturityDate?: string;
  paymentReduction?: number;
  isOnTerm?: boolean;
  timeToMaturityReduction?: TimeMeasure;
  marketDataDateTime?: string;
  firstInstalmentFreeRepaymentDate?: string | null;
  calculationPath?: string | null;
  amortizationProfile?: AmortizationProfile;
  fixedInterestYears?: number | null;
  fixedInterestPeriodType?: FixedInterestPeriodType;
  manualSpread?: number | null;
  scrap?: number | null;
  fixedFirstRepayment?: number | null;
  averageDuration?: number | null;
  installmentFreeTerms?: number | null;
};
export type ValidationResponse = {
  calculationPath: string | null;
  validationMessage?: string | null;
};
export type NewLoanRequest = {
  principalValueDate: string;
  interestPaymentFrequency: PaymentFreqEnum;
  principalAmount?: number;
  loanProduct: ContractLoanTypeEnum;
  amortizationProfile?: AmortizationProfile;
  maturityDateUnadjusted?: string;
  fixedInterestPeriodType?: FixedInterestPeriodType;
  fixedInterestYears?: number | null;
  firstPayDateUnadjusted?: string;
  instalmentFreeTerms?: number;
  marginRate?: number;
  marginMethod?: MarginCalculationMethod;
};
export type ConversionRequest = NewLoanRequest & {
  redeemedContracts?: string[] | null;
};
export type NewCreditRequest = NewLoanRequest & {
  creditMaximum?: number;
  interestIncreasesRemainingDebt?: boolean;
};
export type NewLeasingRequest = {
  principalValueDate: string;
  interestPaymentFrequency: PaymentFreqEnum;
  principalAmount: number;
  loanProduct: ContractLoanTypeEnum;
  amortizationProfile?: AmortizationProfile;
  fixedInterestPeriodType?: FixedInterestPeriodType;
  fixedInterestYears?: number | null;
  instalmentFreeTerms?: number;
  marginRate?: number;
  marginMethod?: MarginCalculationMethod;
  scrap?: number;
  numberOfPayments: number;
  leasingFee: number;
  fixedFirstRepayment?: number | null;
  prePaid?: boolean;
};
export type NewLeasingRequestRead = {
  principalValueDate: string;
  interestPaymentFrequency: PaymentFreqEnum;
  principalAmount: number;
  loanProduct: ContractLoanTypeEnum;
  amortizationProfile?: AmortizationProfile;
  maturityDateUnadjusted?: string;
  fixedInterestPeriodType?: FixedInterestPeriodType;
  fixedInterestYears?: number | null;
  firstPayDateUnadjusted?: string;
  instalmentFreeTerms?: number;
  marginRate?: number;
  marginMethod?: MarginCalculationMethod;
  scrap?: number;
  numberOfPayments: number;
  leasingFee: number;
  fixedFirstRepayment?: number | null;
  prePaid?: boolean;
};
export type PartialRedemptionRequest = {
  amount?: number;
  appliedDate?: string;
  changeMethod?: ChangeMethodCode;
};
export type FullRepaymentRequest = {
  appliedDate?: string;
};
export type PaymentNoticeAdresse = {
  adresse?: string | null;
  journalNummer?: string | null;
  journalLoebenummer?: string | null;
  juridiskNavn?: string | null;
  adresseLinie1?: string | null;
  adresseLinie2?: string | null;
  adresseLinie3?: string | null;
  adresseLinie4?: string | null;
  adresseLinie5?: string | null;
  adresseLinie6?: string | null;
  attention?: string | null;
  telefon?: string | null;
  direkteTelefon?: string | null;
  fax?: string | null;
  email?: string | null;
  seNummer?: string | null;
  postnummer?: string | null;
  bynavn?: string | null;
  land?: string | null;
  bemaerkning?: string | null;
};
export type PaymentNoticePaymentElement = {
  delydelse?: number | null;
  advis?: number;
  betalingsKategori?: string | null;
  betalingsType?: string | null;
  nettoBeloeb?: number;
  sats?: number | null;
  renteDage?: number | null;
  renteBeregnesFra?: string | null;
  renteBeregnesTil?: string | null;
  referenceRente?: string | null;
  aflaesningsDato?: string | null;
  spread?: number | null;
  elementNummer?: number;
  kontoStreng?: string | null;
};
export type PaymentNoticePartPayment = {
  delydelse?: number;
  advis?: number;
  kontraktBen?: string | null;
  bankKonto?: string | null;
  valuta?: string | null;
  retning?: string | null;
  betalesI?: string | null;
  transaktionsKurs?: number | null;
  paymentElements?: PaymentNoticePaymentElement[] | null;
};
export type PaymentNoticeContractLeg = {
  advis?: number;
  kontraktBen?: string | null;
  oprHovedstolBeloeb?: number;
  oprHovedstolValuta?: string | null;
  oprHovedstolDkk?: number | null;
  obligationsRestgaeld?: number | null;
  restgaeldValuta?: string | null;
  gammelRestgaeld?: number | null;
  nyRestgaeld?: number;
  indeks?: string | null;
  indeksDato?: string | null;
  indeksVaerdi?: number | null;
  indeksReguleretBeloeb?: number | null;
  projektNavn?: string | null;
  partPayments?: PaymentNoticePartPayment[] | null;
};
export type PaymentNoticeContract = {
  hovedAdvis?: number | null;
  derivatRelation?: string | null;
  advis?: number;
  advisType?: string | null;
  advisReference?: number | null;
  status?: string | null;
  valoerDato?: string | null;
  forfaldsDato?: string | null;
  periodeFra?: string | null;
  periodeTil?: string | null;
  betalingsform?: string | null;
  pbsNummer?: string | null;
  kontrakt?: string | null;
  kontraktType?: string | null;
  kontraktGruppe?: string | null;
  advisGruppe?: string | null;
  fondsKode?: string | null;
  serieBetegnelse?: string | null;
  kuponRente?: number | null;
  afdelingsBetegnelse?: number | null;
  obligationsType?: string | null;
  obligationsTypeBeskrivelse?: string | null;
  papirKurs?: number | null;
  eanKode?: number | null;
  kontaktPerson?: string | null;
  ordreNummer?: string | null;
  projektNavn?: string | null;
  hovedAdvisReference?: number | null;
  debitorAdresse?: PaymentNoticeAdresse;
  advisAdresse?: PaymentNoticeAdresse;
  kvitterAdresse?: PaymentNoticeAdresse;
  contractLegs?: PaymentNoticeContractLeg[] | null;
};
/**
 * Retrieve Allocation from LAS
 */
export function getAllocationGetallocationByAllocationId(
  allocationId: number,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: AllocationResponse;
    }>(`/allocation/getallocation/${encodeURIComponent(allocationId)}`, {
      ...opts,
    }),
  );
}
/**
 * Retrieve Allocation from LAS
 */
export function getAllocationGetallocationbycompanyidByCompanyId(
  companyId: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: AllocationResponse[];
    }>(
      `/allocation/getallocationbycompanyid/${encodeURIComponent(companyId)}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Create new allocation
 */
export function postAllocationNewallocation(
  {
    allocationName,
    crmGuid,
  }: {
    allocationName?: string;
    crmGuid?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: number;
    }>(
      `/allocation/newallocation${QS.query(
        QS.explode({
          allocationName,
          CRMGuid: crmGuid,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
/**
 * Update existing allocation
 */
export function putAllocationUpdateallocationByAllocationId(
  allocationId: number,
  allocationRequest?: AllocationRequest,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: number;
    }>(
      `/allocation/updateallocation/${encodeURIComponent(allocationId)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: allocationRequest,
      }),
    ),
  );
}
/**
 * Create new contract draft
 */
export function postAllocationNewcontractDraft(
  {
    allocationId,
    projectName,
  }: {
    allocationId?: number;
    projectName?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: number;
    }>(
      `/allocation/newcontractDraft${QS.query(
        QS.explode({
          allocationId,
          projectName,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
/**
 * Update existing Contract Draft
 */
export function putAllocationUpdatecontractDraftByContractDraftId(
  contractDraftId: string,
  contractDraftRequest?: ContractDraftRequest,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: number;
    }>(
      `/allocation/updatecontractDraft/${encodeURIComponent(contractDraftId)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: contractDraftRequest,
      }),
    ),
  );
}
/**
 * Retrieve Contract Draft from LAS
 */
export function getAllocationGetcontractDraftByContractDraftId(
  contractDraftId: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: AllocationContract;
    }>(`/allocation/getcontractDraft/${encodeURIComponent(contractDraftId)}`, {
      ...opts,
    }),
  );
}
/**
 * Retrieve Contract Draft Relations from LAS
 */
export function getAllocationGetcontractdraftrelationsByContractDraftId(
  contractDraftId: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: ContractDraftReleationsResponse;
    }>(
      `/allocation/getcontractdraftrelations/${encodeURIComponent(contractDraftId)}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Retrieve guarantor informations for company
 */
export function getAllocationGetcompanyguarantorsByCompanyId(
  companyId: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: GuarantorResponse[];
    }>(`/allocation/getcompanyguarantors/${encodeURIComponent(companyId)}`, {
      ...opts,
    }),
  );
}
/**
 * Update guarantor relation informations for existing Contract Draft
 */
export function putAllocationUpdateguaranteerelationsforcontractdraftByContractDraftId(
  contractDraftId: string,
  guaranteeRelationRequest?: GuaranteeRelationRequest,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: number;
    }>(
      `/allocation/updateguaranteerelationsforcontractdraft/${encodeURIComponent(contractDraftId)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: guaranteeRelationRequest,
      }),
    ),
  );
}
/**
 * Retrieve EAN information for company
 */
export function getAllocationGetCompanyEanByCompanyId(
  companyId: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: EanInformation[];
    }>(`/allocation/getCompanyEAN/${encodeURIComponent(companyId)}`, {
      ...opts,
    }),
  );
}
/**
 * Update/InsertPayment Advise informations on existing Contract Draft into LAS
 */
export function putAllocationUpdatecontractdraftpaymentadviseinformationsByContractDraftId(
  contractDraftId: string,
  paymentAdviseRequest?: PaymentAdviseRequest,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: number;
    }>(
      `/allocation/updatecontractdraftpaymentadviseinformations/${encodeURIComponent(contractDraftId)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: paymentAdviseRequest,
      }),
    ),
  );
}
/**
 * Update industry code information on existing Contract Draft into LAS
 */
export function putAllocationUpdatecontractdraftindustrycodeinformationByContractDraftId(
  contractDraftId: string,
  {
    industryCode,
  }: {
    industryCode?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: string;
    }>(
      `/allocation/updatecontractdraftindustrycodeinformation/${encodeURIComponent(contractDraftId)}${QS.query(
        QS.explode({
          industryCode,
        }),
      )}`,
      {
        ...opts,
        method: 'PUT',
      },
    ),
  );
}
/**
 * Update/Insert guarantee requirement for existing Contract Draft into LAS
 */
export function putAllocationUpdatecontractdraftguaranteerequirementByContractDraftId(
  contractDraftId: string,
  contractDraftGuaranteeInformationRequest?: ContractDraftGuaranteeInformationRequest,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: number;
    }>(
      `/allocation/updatecontractdraftguaranteerequirement/${encodeURIComponent(contractDraftId)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: contractDraftGuaranteeInformationRequest,
      }),
    ),
  );
}
/**
 * Get loan information given information about companyLasId and/or contractId
 */
export function getSearchloans(
  {
    companyLasId,
    contractId,
    loanNo,
  }: {
    companyLasId?: string;
    contractId?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: LoanSearchResponse[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/searchloans${QS.query(
        QS.explode({
          companyLasId,
          contractId,
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Get information about loan relations given information about companyLasId and/or contractId
 */
export function getSearchloanrelations(
  {
    relationCompanyLasId,
    contractId,
  }: {
    relationCompanyLasId?: string;
    contractId?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: LoanSearchRelationResponse[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/searchloanrelations${QS.query(
        QS.explode({
          relationCompanyLasId,
          contractId,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
export function getTestdeployment(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >('/testdeployment', {
      ...opts,
    }),
  );
}
/**
 * Gets a list of holidays based on usage
 */
export function getCalculationByCalculationPathHolidaysAndHolidayUsage(
  calculationPath: string,
  holidayUsage: HolidayUsage,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: HolidayResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/${encodeURIComponent(calculationPath)}/holidays/${encodeURIComponent(holidayUsage)}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Get a freshly calculated interestsheet.
 */
export function getCalculationInterestsheet(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: InterestSheetResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >('/calculation/interestsheet', {
      ...opts,
    }),
  );
}
/**
 * Get a freshly calculated pricesheet.
 */
export function getCalculationPricesheet(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: PriceSheetResponse[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >('/calculation/pricesheet', {
      ...opts,
    }),
  );
}
/**
 * Retrieves loan portfolio for client
 */
export function getCustomerByCompanyIdEodloanportfolio(
  companyId: string,
  {
    date,
  }: {
    date?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: PortfolioInfoResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/customer/${encodeURIComponent(companyId)}/eodloanportfolio${QS.query(
        QS.explode({
          date,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Get the earlist valid filter date for customer page agreement overviews.
 */
export function getCustomerEarliestvalidfilterdate(
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >('/customer/earliestvalidfilterdate', {
      ...opts,
    }),
  );
}
/**
 * Get eligable loans based on CompanyId and EventType
 */
export function getEligibleloansByCompanyId(
  companyId: string,
  {
    eventDescription,
  }: {
    eventDescription?: EventDescriptionCode;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: EligibleLoanResponse[];
        }
      | {
          status: 204;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/eligibleloans/${encodeURIComponent(companyId)}${QS.query(
        QS.explode({
          eventDescription,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Send a stored calculation given a calculation path to LAS for execution. Receive a Boolean indication telling whether LAS accepted the request
 */
export function getExecutecalculationByCalculationPath(
  calculationPath: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: boolean;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/executecalculation/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Fetch a stored calculation by calculation path from storage account
 */
export function getFetchcalculationfromstorageByCalculationPath(
  calculationPath: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CalculationInfoResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/fetchcalculationfromstorage/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Get credit calculation from storage
 */
export function getFetchcreditcalculationfromstorageByCalculationPath(
  calculationPath: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CreditInfoResponse[];
        }
      | {
          status: 204;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/fetchcreditcalculationfromstorage/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Fetch a stored calculation by calculation path from storage account and Receive it as a file
 */
export function getFetchfilefromstorageByCalculationPath(
  calculationPath: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CalculationInfoResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(`/fetchfilefromstorage/${encodeURIComponent(calculationPath)}`, {
      ...opts,
    }),
  );
}
/**
 * Get a list of valid first interest payment dates for credit loans and customers in Greenland.
 */
export function getFirstinterestpaymentdateByStartDate(
  startDate: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: FirstInterestPaymentDateResponse;
        }
      | {
          status: 204;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(`/firstinterestpaymentdate/${encodeURIComponent(startDate)}`, {
      ...opts,
    }),
  );
}
/**
 * Get credit information from LAS for a given contractId
 */
export function getGetcreditinfoByContractId(
  contractId: string,
  {
    calculationDate,
  }: {
    calculationDate?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CreditInfoResponse[];
        }
      | {
          status: 204;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/getcreditinfo/${encodeURIComponent(contractId)}${QS.query(
        QS.explode({
          CalculationDate: calculationDate,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Get loan information from LAS for a given contractId
 */
export function getLoaninfoByContractId(
  contractId: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: (LoanInfoResponse | CalculationInfoResponse)[];
        }
      | {
          status: 204;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/loaninfo/${encodeURIComponent(contractId)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Get a list of possible redemption dates for a loan on a given calculation path
 */
export function getPartialredemptiondateByCalculationPath(
  calculationPath: string,
  {
    runDate,
    loanNo,
  }: {
    runDate?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: PartialRedemptionTermDateResponse;
        }
      | {
          status: 204;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/partialredemptiondate/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          RunDate: runDate,
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Get a status for executing a calculation from LAS
 */
export function getStatusexecutecalculationByCalculationPath(
  calculationPath: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: RedemptionStatusResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(`/statusexecutecalculation/${encodeURIComponent(calculationPath)}`, {
      ...opts,
    }),
  );
}
/**
 * Extract additional information on calculations
 */
export function postCalculationByCalculationPathGetcalculationdetails(
  calculationPath: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CalculationInfo;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/${encodeURIComponent(calculationPath)}/getcalculationdetails${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
/**
 * Update a calculation by calculation path and Receive a new calculation path on the updated calculation
 */
export function postCalculationByCalculationPathUpdate(
  calculationPath: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/${encodeURIComponent(calculationPath)}/update${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
/**
 * Add a manual compensation amount to an existing calculation given the calculation path. Receive a new calculation path on the updated calculation
 */
export function postCalculationAddmanualcompensationamountByCalculationPath(
  calculationPath: string,
  body?: number,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/addmanualcompensationamount/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      }),
    ),
  );
}
/**
 * Fix the interest rate on existing calculation given the calculation path. Receive a new calculation path on the updated calculation
 */
export function postCalculationFixinterestrateByCalculationPath(
  calculationPath: string,
  body?: number,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/fixinterestrate/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      }),
    ),
  );
}
/**
 * Create a renewed interest agreement calculation in the storage account given a contractId. Receive the calculation path on the new created calculation. Returns a conflict with an error message if the agreement is not supported for calculation.
 */
export function postCalculationRenewinterestagreementByContractId(
  contractId: string,
  {
    fixedInterestPeriodType,
    fixedInterestYears,
    loanNo,
  }: {
    fixedInterestPeriodType?: FixedInterestPeriodType;
    fixedInterestYears?: number;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 409;
          data: string;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/renewinterestagreement/${encodeURIComponent(contractId)}${QS.query(
        QS.explode({
          fixedInterestPeriodType,
          fixedInterestYears,
          loanNo,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
/**
 * Create a new calculation in the storage account given a contractId for an existing loan in LAS. Receive the calculation path on the new created calculation
 */
export function postCreatecalculationByContractId(
  contractId: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/createcalculation/${encodeURIComponent(contractId)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
/**
 * Validate data in a calculation given by a calculation path
 */
export function postValidatecalculationsByRequestId(
  requestId: string,
  body?: string[],
  {
    runDate,
  }: {
    runDate?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: ValidationResponse[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/validatecalculations/${encodeURIComponent(requestId)}${QS.query(
        QS.explode({
          runDate,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      }),
    ),
  );
}
/**
 * Add a new event to an existing calculation given a calculation path. Receive a new calculation path on the updated calculation
 */
export function putAddcalculationeventConversion(
  conversionRequest?: ConversionRequest,
  {
    runDate,
  }: {
    runDate?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/addcalculationevent/conversion${QS.query(
        QS.explode({
          runDate,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: conversionRequest,
      }),
    ),
  );
}
/**
 * Add a new credit to an existing calculation given a calculation path. Receive a new calculation path on the updated calculation
 */
export function putAddcalculationeventNewcreditByCalculationPath(
  calculationPath: string,
  newCreditRequest?: NewCreditRequest,
  {
    runDate,
    loanNo,
  }: {
    runDate?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/addcalculationevent/newcredit/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          runDate,
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: newCreditRequest,
      }),
    ),
  );
}
/**
 * Add a new event to an existing calculation given a calculation path. Receive a new calculation path on the updated calculation
 */
export function putAddcalculationeventNewleasingByCalculationPath(
  calculationPath: string,
  newLeasingRequest?: NewLeasingRequest,
  {
    runDate,
    loanNo,
  }: {
    runDate?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/addcalculationevent/newleasing/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          runDate,
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: newLeasingRequest,
      }),
    ),
  );
}
/**
 * Add a new event to an existing calculation given a calculation path. Receive a new calculation path on the updated calculation
 */
export function putAddcalculationeventNewloanByCalculationPath(
  calculationPath: string,
  body?: NewLoanRequest | ConversionRequest | NewCreditRequest,
  {
    runDate,
    loanNo,
  }: {
    runDate?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/addcalculationevent/newloan/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          runDate,
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body,
      }),
    ),
  );
}
/**
 * Add a new event to an existing calculation given a calculation path. Receive a new calculation path on the updated calculation
 */
export function putAddcalculationeventByCalculationPathExtrarepayment(
  calculationPath: string,
  partialRedemptionRequest?: PartialRedemptionRequest,
  {
    runDate,
    loanNo,
  }: {
    runDate?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/addcalculationevent/${encodeURIComponent(calculationPath)}/extrarepayment${QS.query(
        QS.explode({
          runDate,
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialRedemptionRequest,
      }),
    ),
  );
}
/**
 * Add a new event to an existing calculation given a calculation path. Receive a new calculation path on the updated calculation
 */
export function putAddcalculationeventByCalculationPathFullredemption(
  calculationPath: string,
  fullRepaymentRequest?: FullRepaymentRequest,
  {
    runDate,
    loanNo,
  }: {
    runDate?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/addcalculationevent/${encodeURIComponent(calculationPath)}/fullredemption${QS.query(
        QS.explode({
          runDate,
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: fullRepaymentRequest,
      }),
    ),
  );
}
/**
 * Create a new CalculationPath for new loan
 */
export function putCreatenewloancalculationpath(
  {
    contractId,
    loanNo,
  }: {
    contractId?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/createnewloancalculationpath${QS.query(
        QS.explode({
          contractId,
          loanNo,
        }),
      )}`,
      {
        ...opts,
        method: 'PUT',
      },
    ),
  );
}
/**
 * Create a new CalculationPath for StandAlone
 */
export function putCreatestandalonecalculationpath(
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/createstandalonecalculationpath${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
        method: 'PUT',
      },
    ),
  );
}
/**
 * Get a list of Payment Notices for processing
 */
export function getPaymentnoticeGetpaymentnoticeforprocessing(
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: number[];
    }>('/paymentnotice/getpaymentnoticeforprocessing', {
      ...opts,
    }),
  );
}
/**
 * Retrieve the notices connected to the main notice in LAS
 */
export function getPaymentnoticeGetpaymentnotice(
  {
    notice,
  }: {
    notice?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: PaymentNoticeContract[];
    }>(
      `/paymentnotice/getpaymentnotice${QS.query(
        QS.explode({
          notice,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Update all notices with main notice id to handled
 */
export function postPaymentnoticePostupdatenotice(
  {
    mainNoticeId,
  }: {
    mainNoticeId?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: boolean;
    }>(
      `/paymentnotice/postupdatenotice${QS.query(
        QS.explode({
          mainNoticeId,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
export enum ContractLoanTypeEnum {
  NurUdlan = 'NUR_udl\u00E5n',
  UdlanModelB = 'Udl\u00E5n_Model_B',
  KKvar = 'KKvar',
  KKmax = 'KKmax',
  KKbasis = 'KKbasis',
  KKcibor = 'KKcibor',
  KKcita = 'KKcita',
  KKfast = 'KKfast',
  K4Udlan = 'K4_udl\u00E5n',
  AiKontant = 'AI_kontant',
  Rentetilpasningslan = 'Rentetilpasningsl\u00E5n',
  Indekslan = 'Indeksl\u00E5n',
  Obligationslan = 'Obligationsl\u00E5n',
  AiLan = 'AI_l\u00E5n',
  Swap = 'Swap',
  Renteswap = 'Renteswap',
  Valutaswap = 'Valutaswap',
  ValutaTermin = 'ValutaTermin',
  ValutaSpot = 'ValutaSpot',
  KontantlaanAiKontant = 'Kontantlaan_AI_kontant',
  KontantlaanK4Udlan = 'Kontantlaan_K4_udl\u00E5n',
  Byggekredit = 'Byggekredit',
  UnDefined = 'UnDefined',
  Kkkur = 'KKKUR',
}
export enum PaymentAdviseTypes {
  Ean = 'EAN',
  Bs = 'BS',
  Pdf = 'PDF',
}
export enum GuaranteeType {
  Guarantor = 'Guarantor',
  StakeHolder = 'StakeHolder',
}
export enum ContractStatus {
  NotYetActive = 'NotYetActive',
  Active = 'Active',
  Archived = 'Archived',
}
export enum HolidayUsage {
  StandAlone = 'StandAlone',
  NewLoan = 'NewLoan',
  LoanOverview = 'LoanOverview',
}
export enum AmortizationProfile {
  Bullet = 'Bullet',
  Annuity = 'Annuity',
  Serial = 'Serial',
  Custom = 'Custom',
  UnDefined = 'UnDefined',
}
export enum PaymentFreqEnum {
  UnDefined = 'UnDefined',
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M4 = 'M4',
  M6 = 'M6',
  M12 = 'M12',
}
export enum InterestConvensionEnum {
  ThirtyThreeSixty = 'ThirtyThreeSixty',
  ActAct = 'ActAct',
  ActThreeSixty = 'ActThreeSixty',
  ActThreeSixtyFive = 'ActThreeSixtyFive',
  ThirtyThreeSixtyFive = 'ThirtyThreeSixtyFive',
}
export enum SustainableFrameworkType {
  Gbf2017 = 'GBF2017',
  Gbf2022 = 'GBF2022',
  None = 'None',
}
export enum CurrencyCode {
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
  Chf = 'CHF',
  Undefined = 'Undefined',
}
export enum LoanCategory {
  ContractLoan = 'ContractLoan',
  ConstructionCredit = 'ConstructionCredit',
  BondLoan = 'BondLoan',
  SubsidizedLoan = 'SubsidizedLoan',
  Swap = 'Swap',
  Undefined = 'Undefined',
}
export enum InterestType {
  Fixed = 'Fixed',
  Floating = 'Floating',
  Undefined = 'Undefined',
}
export enum Relation {
  Owner = 'Owner',
  Guarantee = 'Guarantee',
  PartnershipLiability = 'PartnershipLiability',
  BillReceiver = 'BillReceiver',
  Undefined = 'Undefined',
}
export enum EventDescriptionCode {
  Conversion = 'Conversion',
  FullRedemption = 'FullRedemption',
  ExtraRepayment = 'ExtraRepayment',
  NewLoan = 'NewLoan',
  Redemption = 'Redemption',
}
export enum InterestRateStatus {
  Fixed = 'Fixed',
  Estimated = 'Estimated',
  BeforeInterestConditionExpiryDate = 'BeforeInterestConditionExpiryDate',
  AfterInterestConditionExpiryDate = 'AfterInterestConditionExpiryDate',
}
export enum ChangeMethodCode {
  ReducedTimeToMaturity = 'ReducedTimeToMaturity',
  ReducedPayment = 'ReducedPayment',
  NewLoan = 'NewLoan',
}
export enum FixedInterestPeriodType {
  Cheapest = 'Cheapest',
  ToMaturity = 'ToMaturity',
  Years = 'Years',
  UnDefined = 'UnDefined',
}
export enum RedemptionStatusEnum {
  NotStarted = 'NotStarted',
  Ok = 'OK',
  Error = 'Error',
  Running = 'Running',
  NotRelevant = 'NotRelevant',
  Undefined = 'Undefined',
}
export enum MarginCalculationMethod {
  MarginCalculatedByRemainingDebt = 'MarginCalculatedByRemainingDebt',
  MarginCalculatedByPrincipal = 'MarginCalculatedByPrincipal',
}

// #region INJECTED PATHS
/* The generated output is not a direct result of oazapfts' default behavior.
 * It instead incorporates our custom code, which adds path placeholder properties for MSW mocking, into the output of oazapfts. */
// {{{

const _getAllocationGetallocationByAllocationIdPath = () =>
  defaults.baseUrl + '/allocation/getallocation/:allocationId';
Object.defineProperty(getAllocationGetallocationByAllocationId, 'path', {
  get: _getAllocationGetallocationByAllocationIdPath,
  set: _getAllocationGetallocationByAllocationIdPath,
});
/** @path `/allocation/getallocation/:allocationId` */
getAllocationGetallocationByAllocationId.path =
  getAllocationGetallocationByAllocationId.path satisfies string;

const _getAllocationGetallocationbycompanyidByCompanyIdPath = () =>
  defaults.baseUrl + '/allocation/getallocationbycompanyid/:companyId';
Object.defineProperty(
  getAllocationGetallocationbycompanyidByCompanyId,
  'path',
  {
    get: _getAllocationGetallocationbycompanyidByCompanyIdPath,
    set: _getAllocationGetallocationbycompanyidByCompanyIdPath,
  },
);
/** @path `/allocation/getallocationbycompanyid/:companyId` */
getAllocationGetallocationbycompanyidByCompanyId.path =
  getAllocationGetallocationbycompanyidByCompanyId.path satisfies string;

const _postAllocationNewallocationPath = () =>
  defaults.baseUrl + '/allocation/newallocation';
Object.defineProperty(postAllocationNewallocation, 'path', {
  get: _postAllocationNewallocationPath,
  set: _postAllocationNewallocationPath,
});
/** @path `/allocation/newallocation` */
postAllocationNewallocation.path =
  postAllocationNewallocation.path satisfies string;

const _putAllocationUpdateallocationByAllocationIdPath = () =>
  defaults.baseUrl + '/allocation/updateallocation/:allocationId';
Object.defineProperty(putAllocationUpdateallocationByAllocationId, 'path', {
  get: _putAllocationUpdateallocationByAllocationIdPath,
  set: _putAllocationUpdateallocationByAllocationIdPath,
});
/** @path `/allocation/updateallocation/:allocationId` */
putAllocationUpdateallocationByAllocationId.path =
  putAllocationUpdateallocationByAllocationId.path satisfies string;

const _postAllocationNewcontractDraftPath = () =>
  defaults.baseUrl + '/allocation/newcontractDraft';
Object.defineProperty(postAllocationNewcontractDraft, 'path', {
  get: _postAllocationNewcontractDraftPath,
  set: _postAllocationNewcontractDraftPath,
});
/** @path `/allocation/newcontractDraft` */
postAllocationNewcontractDraft.path =
  postAllocationNewcontractDraft.path satisfies string;

const _putAllocationUpdatecontractDraftByContractDraftIdPath = () =>
  defaults.baseUrl + '/allocation/updatecontractDraft/:contractDraftId';
Object.defineProperty(
  putAllocationUpdatecontractDraftByContractDraftId,
  'path',
  {
    get: _putAllocationUpdatecontractDraftByContractDraftIdPath,
    set: _putAllocationUpdatecontractDraftByContractDraftIdPath,
  },
);
/** @path `/allocation/updatecontractDraft/:contractDraftId` */
putAllocationUpdatecontractDraftByContractDraftId.path =
  putAllocationUpdatecontractDraftByContractDraftId.path satisfies string;

const _getAllocationGetcontractDraftByContractDraftIdPath = () =>
  defaults.baseUrl + '/allocation/getcontractDraft/:contractDraftId';
Object.defineProperty(getAllocationGetcontractDraftByContractDraftId, 'path', {
  get: _getAllocationGetcontractDraftByContractDraftIdPath,
  set: _getAllocationGetcontractDraftByContractDraftIdPath,
});
/** @path `/allocation/getcontractDraft/:contractDraftId` */
getAllocationGetcontractDraftByContractDraftId.path =
  getAllocationGetcontractDraftByContractDraftId.path satisfies string;

const _getAllocationGetcontractdraftrelationsByContractDraftIdPath = () =>
  defaults.baseUrl + '/allocation/getcontractdraftrelations/:contractDraftId';
Object.defineProperty(
  getAllocationGetcontractdraftrelationsByContractDraftId,
  'path',
  {
    get: _getAllocationGetcontractdraftrelationsByContractDraftIdPath,
    set: _getAllocationGetcontractdraftrelationsByContractDraftIdPath,
  },
);
/** @path `/allocation/getcontractdraftrelations/:contractDraftId` */
getAllocationGetcontractdraftrelationsByContractDraftId.path =
  getAllocationGetcontractdraftrelationsByContractDraftId.path satisfies string;

const _getAllocationGetcompanyguarantorsByCompanyIdPath = () =>
  defaults.baseUrl + '/allocation/getcompanyguarantors/:companyId';
Object.defineProperty(getAllocationGetcompanyguarantorsByCompanyId, 'path', {
  get: _getAllocationGetcompanyguarantorsByCompanyIdPath,
  set: _getAllocationGetcompanyguarantorsByCompanyIdPath,
});
/** @path `/allocation/getcompanyguarantors/:companyId` */
getAllocationGetcompanyguarantorsByCompanyId.path =
  getAllocationGetcompanyguarantorsByCompanyId.path satisfies string;

const _putAllocationUpdateguaranteerelationsforcontractdraftByContractDraftIdPath =
  () =>
    defaults.baseUrl +
    '/allocation/updateguaranteerelationsforcontractdraft/:contractDraftId';
Object.defineProperty(
  putAllocationUpdateguaranteerelationsforcontractdraftByContractDraftId,
  'path',
  {
    get: _putAllocationUpdateguaranteerelationsforcontractdraftByContractDraftIdPath,
    set: _putAllocationUpdateguaranteerelationsforcontractdraftByContractDraftIdPath,
  },
);
/** @path `/allocation/updateguaranteerelationsforcontractdraft/:contractDraftId` */
putAllocationUpdateguaranteerelationsforcontractdraftByContractDraftId.path =
  putAllocationUpdateguaranteerelationsforcontractdraftByContractDraftId.path satisfies string;

const _getAllocationGetCompanyEanByCompanyIdPath = () =>
  defaults.baseUrl + '/allocation/getCompanyEAN/:companyId';
Object.defineProperty(getAllocationGetCompanyEanByCompanyId, 'path', {
  get: _getAllocationGetCompanyEanByCompanyIdPath,
  set: _getAllocationGetCompanyEanByCompanyIdPath,
});
/** @path `/allocation/getCompanyEAN/:companyId` */
getAllocationGetCompanyEanByCompanyId.path =
  getAllocationGetCompanyEanByCompanyId.path satisfies string;

const _putAllocationUpdatecontractdraftpaymentadviseinformationsByContractDraftIdPath =
  () =>
    defaults.baseUrl +
    '/allocation/updatecontractdraftpaymentadviseinformations/:contractDraftId';
Object.defineProperty(
  putAllocationUpdatecontractdraftpaymentadviseinformationsByContractDraftId,
  'path',
  {
    get: _putAllocationUpdatecontractdraftpaymentadviseinformationsByContractDraftIdPath,
    set: _putAllocationUpdatecontractdraftpaymentadviseinformationsByContractDraftIdPath,
  },
);
/** @path `/allocation/updatecontractdraftpaymentadviseinformations/:contractDraftId` */
putAllocationUpdatecontractdraftpaymentadviseinformationsByContractDraftId.path =
  putAllocationUpdatecontractdraftpaymentadviseinformationsByContractDraftId.path satisfies string;

const _putAllocationUpdatecontractdraftindustrycodeinformationByContractDraftIdPath =
  () =>
    defaults.baseUrl +
    '/allocation/updatecontractdraftindustrycodeinformation/:contractDraftId';
Object.defineProperty(
  putAllocationUpdatecontractdraftindustrycodeinformationByContractDraftId,
  'path',
  {
    get: _putAllocationUpdatecontractdraftindustrycodeinformationByContractDraftIdPath,
    set: _putAllocationUpdatecontractdraftindustrycodeinformationByContractDraftIdPath,
  },
);
/** @path `/allocation/updatecontractdraftindustrycodeinformation/:contractDraftId` */
putAllocationUpdatecontractdraftindustrycodeinformationByContractDraftId.path =
  putAllocationUpdatecontractdraftindustrycodeinformationByContractDraftId.path satisfies string;

const _putAllocationUpdatecontractdraftguaranteerequirementByContractDraftIdPath =
  () =>
    defaults.baseUrl +
    '/allocation/updatecontractdraftguaranteerequirement/:contractDraftId';
Object.defineProperty(
  putAllocationUpdatecontractdraftguaranteerequirementByContractDraftId,
  'path',
  {
    get: _putAllocationUpdatecontractdraftguaranteerequirementByContractDraftIdPath,
    set: _putAllocationUpdatecontractdraftguaranteerequirementByContractDraftIdPath,
  },
);
/** @path `/allocation/updatecontractdraftguaranteerequirement/:contractDraftId` */
putAllocationUpdatecontractdraftguaranteerequirementByContractDraftId.path =
  putAllocationUpdatecontractdraftguaranteerequirementByContractDraftId.path satisfies string;

const _getSearchloansPath = () => defaults.baseUrl + '/searchloans';
Object.defineProperty(getSearchloans, 'path', {
  get: _getSearchloansPath,
  set: _getSearchloansPath,
});
/** @path `/searchloans` */
getSearchloans.path = getSearchloans.path satisfies string;

const _getSearchloanrelationsPath = () =>
  defaults.baseUrl + '/searchloanrelations';
Object.defineProperty(getSearchloanrelations, 'path', {
  get: _getSearchloanrelationsPath,
  set: _getSearchloanrelationsPath,
});
/** @path `/searchloanrelations` */
getSearchloanrelations.path = getSearchloanrelations.path satisfies string;

const _getTestdeploymentPath = () => defaults.baseUrl + '/testdeployment';
Object.defineProperty(getTestdeployment, 'path', {
  get: _getTestdeploymentPath,
  set: _getTestdeploymentPath,
});
/** @path `/testdeployment` */
getTestdeployment.path = getTestdeployment.path satisfies string;

const _getCalculationByCalculationPathHolidaysAndHolidayUsagePath = () =>
  defaults.baseUrl + '/calculation/:calculationPath/holidays/:holidayUsage';
Object.defineProperty(
  getCalculationByCalculationPathHolidaysAndHolidayUsage,
  'path',
  {
    get: _getCalculationByCalculationPathHolidaysAndHolidayUsagePath,
    set: _getCalculationByCalculationPathHolidaysAndHolidayUsagePath,
  },
);
/** @path `/calculation/:calculationPath/holidays/:holidayUsage` */
getCalculationByCalculationPathHolidaysAndHolidayUsage.path =
  getCalculationByCalculationPathHolidaysAndHolidayUsage.path satisfies string;

const _getCalculationInterestsheetPath = () =>
  defaults.baseUrl + '/calculation/interestsheet';
Object.defineProperty(getCalculationInterestsheet, 'path', {
  get: _getCalculationInterestsheetPath,
  set: _getCalculationInterestsheetPath,
});
/** @path `/calculation/interestsheet` */
getCalculationInterestsheet.path =
  getCalculationInterestsheet.path satisfies string;

const _getCalculationPricesheetPath = () =>
  defaults.baseUrl + '/calculation/pricesheet';
Object.defineProperty(getCalculationPricesheet, 'path', {
  get: _getCalculationPricesheetPath,
  set: _getCalculationPricesheetPath,
});
/** @path `/calculation/pricesheet` */
getCalculationPricesheet.path = getCalculationPricesheet.path satisfies string;

const _getCustomerByCompanyIdEodloanportfolioPath = () =>
  defaults.baseUrl + '/customer/:companyId/eodloanportfolio';
Object.defineProperty(getCustomerByCompanyIdEodloanportfolio, 'path', {
  get: _getCustomerByCompanyIdEodloanportfolioPath,
  set: _getCustomerByCompanyIdEodloanportfolioPath,
});
/** @path `/customer/:companyId/eodloanportfolio` */
getCustomerByCompanyIdEodloanportfolio.path =
  getCustomerByCompanyIdEodloanportfolio.path satisfies string;

const _getCustomerEarliestvalidfilterdatePath = () =>
  defaults.baseUrl + '/customer/earliestvalidfilterdate';
Object.defineProperty(getCustomerEarliestvalidfilterdate, 'path', {
  get: _getCustomerEarliestvalidfilterdatePath,
  set: _getCustomerEarliestvalidfilterdatePath,
});
/** @path `/customer/earliestvalidfilterdate` */
getCustomerEarliestvalidfilterdate.path =
  getCustomerEarliestvalidfilterdate.path satisfies string;

const _getEligibleloansByCompanyIdPath = () =>
  defaults.baseUrl + '/eligibleloans/:companyId';
Object.defineProperty(getEligibleloansByCompanyId, 'path', {
  get: _getEligibleloansByCompanyIdPath,
  set: _getEligibleloansByCompanyIdPath,
});
/** @path `/eligibleloans/:companyId` */
getEligibleloansByCompanyId.path =
  getEligibleloansByCompanyId.path satisfies string;

const _getExecutecalculationByCalculationPathPath = () =>
  defaults.baseUrl + '/executecalculation/:calculationPath';
Object.defineProperty(getExecutecalculationByCalculationPath, 'path', {
  get: _getExecutecalculationByCalculationPathPath,
  set: _getExecutecalculationByCalculationPathPath,
});
/** @path `/executecalculation/:calculationPath` */
getExecutecalculationByCalculationPath.path =
  getExecutecalculationByCalculationPath.path satisfies string;

const _getFetchcalculationfromstorageByCalculationPathPath = () =>
  defaults.baseUrl + '/fetchcalculationfromstorage/:calculationPath';
Object.defineProperty(getFetchcalculationfromstorageByCalculationPath, 'path', {
  get: _getFetchcalculationfromstorageByCalculationPathPath,
  set: _getFetchcalculationfromstorageByCalculationPathPath,
});
/** @path `/fetchcalculationfromstorage/:calculationPath` */
getFetchcalculationfromstorageByCalculationPath.path =
  getFetchcalculationfromstorageByCalculationPath.path satisfies string;

const _getFetchcreditcalculationfromstorageByCalculationPathPath = () =>
  defaults.baseUrl + '/fetchcreditcalculationfromstorage/:calculationPath';
Object.defineProperty(
  getFetchcreditcalculationfromstorageByCalculationPath,
  'path',
  {
    get: _getFetchcreditcalculationfromstorageByCalculationPathPath,
    set: _getFetchcreditcalculationfromstorageByCalculationPathPath,
  },
);
/** @path `/fetchcreditcalculationfromstorage/:calculationPath` */
getFetchcreditcalculationfromstorageByCalculationPath.path =
  getFetchcreditcalculationfromstorageByCalculationPath.path satisfies string;

const _getFetchfilefromstorageByCalculationPathPath = () =>
  defaults.baseUrl + '/fetchfilefromstorage/:calculationPath';
Object.defineProperty(getFetchfilefromstorageByCalculationPath, 'path', {
  get: _getFetchfilefromstorageByCalculationPathPath,
  set: _getFetchfilefromstorageByCalculationPathPath,
});
/** @path `/fetchfilefromstorage/:calculationPath` */
getFetchfilefromstorageByCalculationPath.path =
  getFetchfilefromstorageByCalculationPath.path satisfies string;

const _getFirstinterestpaymentdateByStartDatePath = () =>
  defaults.baseUrl + '/firstinterestpaymentdate/:startDate';
Object.defineProperty(getFirstinterestpaymentdateByStartDate, 'path', {
  get: _getFirstinterestpaymentdateByStartDatePath,
  set: _getFirstinterestpaymentdateByStartDatePath,
});
/** @path `/firstinterestpaymentdate/:startDate` */
getFirstinterestpaymentdateByStartDate.path =
  getFirstinterestpaymentdateByStartDate.path satisfies string;

const _getGetcreditinfoByContractIdPath = () =>
  defaults.baseUrl + '/getcreditinfo/:contractId';
Object.defineProperty(getGetcreditinfoByContractId, 'path', {
  get: _getGetcreditinfoByContractIdPath,
  set: _getGetcreditinfoByContractIdPath,
});
/** @path `/getcreditinfo/:contractId` */
getGetcreditinfoByContractId.path =
  getGetcreditinfoByContractId.path satisfies string;

const _getLoaninfoByContractIdPath = () =>
  defaults.baseUrl + '/loaninfo/:contractId';
Object.defineProperty(getLoaninfoByContractId, 'path', {
  get: _getLoaninfoByContractIdPath,
  set: _getLoaninfoByContractIdPath,
});
/** @path `/loaninfo/:contractId` */
getLoaninfoByContractId.path = getLoaninfoByContractId.path satisfies string;

const _getPartialredemptiondateByCalculationPathPath = () =>
  defaults.baseUrl + '/partialredemptiondate/:calculationPath';
Object.defineProperty(getPartialredemptiondateByCalculationPath, 'path', {
  get: _getPartialredemptiondateByCalculationPathPath,
  set: _getPartialredemptiondateByCalculationPathPath,
});
/** @path `/partialredemptiondate/:calculationPath` */
getPartialredemptiondateByCalculationPath.path =
  getPartialredemptiondateByCalculationPath.path satisfies string;

const _getStatusexecutecalculationByCalculationPathPath = () =>
  defaults.baseUrl + '/statusexecutecalculation/:calculationPath';
Object.defineProperty(getStatusexecutecalculationByCalculationPath, 'path', {
  get: _getStatusexecutecalculationByCalculationPathPath,
  set: _getStatusexecutecalculationByCalculationPathPath,
});
/** @path `/statusexecutecalculation/:calculationPath` */
getStatusexecutecalculationByCalculationPath.path =
  getStatusexecutecalculationByCalculationPath.path satisfies string;

const _postCalculationByCalculationPathGetcalculationdetailsPath = () =>
  defaults.baseUrl + '/calculation/:calculationPath/getcalculationdetails';
Object.defineProperty(
  postCalculationByCalculationPathGetcalculationdetails,
  'path',
  {
    get: _postCalculationByCalculationPathGetcalculationdetailsPath,
    set: _postCalculationByCalculationPathGetcalculationdetailsPath,
  },
);
/** @path `/calculation/:calculationPath/getcalculationdetails` */
postCalculationByCalculationPathGetcalculationdetails.path =
  postCalculationByCalculationPathGetcalculationdetails.path satisfies string;

const _postCalculationByCalculationPathUpdatePath = () =>
  defaults.baseUrl + '/calculation/:calculationPath/update';
Object.defineProperty(postCalculationByCalculationPathUpdate, 'path', {
  get: _postCalculationByCalculationPathUpdatePath,
  set: _postCalculationByCalculationPathUpdatePath,
});
/** @path `/calculation/:calculationPath/update` */
postCalculationByCalculationPathUpdate.path =
  postCalculationByCalculationPathUpdate.path satisfies string;

const _postCalculationAddmanualcompensationamountByCalculationPathPath = () =>
  defaults.baseUrl +
  '/calculation/addmanualcompensationamount/:calculationPath';
Object.defineProperty(
  postCalculationAddmanualcompensationamountByCalculationPath,
  'path',
  {
    get: _postCalculationAddmanualcompensationamountByCalculationPathPath,
    set: _postCalculationAddmanualcompensationamountByCalculationPathPath,
  },
);
/** @path `/calculation/addmanualcompensationamount/:calculationPath` */
postCalculationAddmanualcompensationamountByCalculationPath.path =
  postCalculationAddmanualcompensationamountByCalculationPath.path satisfies string;

const _postCalculationFixinterestrateByCalculationPathPath = () =>
  defaults.baseUrl + '/calculation/fixinterestrate/:calculationPath';
Object.defineProperty(postCalculationFixinterestrateByCalculationPath, 'path', {
  get: _postCalculationFixinterestrateByCalculationPathPath,
  set: _postCalculationFixinterestrateByCalculationPathPath,
});
/** @path `/calculation/fixinterestrate/:calculationPath` */
postCalculationFixinterestrateByCalculationPath.path =
  postCalculationFixinterestrateByCalculationPath.path satisfies string;

const _postCalculationRenewinterestagreementByContractIdPath = () =>
  defaults.baseUrl + '/calculation/renewinterestagreement/:contractId';
Object.defineProperty(
  postCalculationRenewinterestagreementByContractId,
  'path',
  {
    get: _postCalculationRenewinterestagreementByContractIdPath,
    set: _postCalculationRenewinterestagreementByContractIdPath,
  },
);
/** @path `/calculation/renewinterestagreement/:contractId` */
postCalculationRenewinterestagreementByContractId.path =
  postCalculationRenewinterestagreementByContractId.path satisfies string;

const _postCreatecalculationByContractIdPath = () =>
  defaults.baseUrl + '/createcalculation/:contractId';
Object.defineProperty(postCreatecalculationByContractId, 'path', {
  get: _postCreatecalculationByContractIdPath,
  set: _postCreatecalculationByContractIdPath,
});
/** @path `/createcalculation/:contractId` */
postCreatecalculationByContractId.path =
  postCreatecalculationByContractId.path satisfies string;

const _postValidatecalculationsByRequestIdPath = () =>
  defaults.baseUrl + '/validatecalculations/:requestId';
Object.defineProperty(postValidatecalculationsByRequestId, 'path', {
  get: _postValidatecalculationsByRequestIdPath,
  set: _postValidatecalculationsByRequestIdPath,
});
/** @path `/validatecalculations/:requestId` */
postValidatecalculationsByRequestId.path =
  postValidatecalculationsByRequestId.path satisfies string;

const _putAddcalculationeventConversionPath = () =>
  defaults.baseUrl + '/addcalculationevent/conversion';
Object.defineProperty(putAddcalculationeventConversion, 'path', {
  get: _putAddcalculationeventConversionPath,
  set: _putAddcalculationeventConversionPath,
});
/** @path `/addcalculationevent/conversion` */
putAddcalculationeventConversion.path =
  putAddcalculationeventConversion.path satisfies string;

const _putAddcalculationeventNewcreditByCalculationPathPath = () =>
  defaults.baseUrl + '/addcalculationevent/newcredit/:calculationPath';
Object.defineProperty(
  putAddcalculationeventNewcreditByCalculationPath,
  'path',
  {
    get: _putAddcalculationeventNewcreditByCalculationPathPath,
    set: _putAddcalculationeventNewcreditByCalculationPathPath,
  },
);
/** @path `/addcalculationevent/newcredit/:calculationPath` */
putAddcalculationeventNewcreditByCalculationPath.path =
  putAddcalculationeventNewcreditByCalculationPath.path satisfies string;

const _putAddcalculationeventNewleasingByCalculationPathPath = () =>
  defaults.baseUrl + '/addcalculationevent/newleasing/:calculationPath';
Object.defineProperty(
  putAddcalculationeventNewleasingByCalculationPath,
  'path',
  {
    get: _putAddcalculationeventNewleasingByCalculationPathPath,
    set: _putAddcalculationeventNewleasingByCalculationPathPath,
  },
);
/** @path `/addcalculationevent/newleasing/:calculationPath` */
putAddcalculationeventNewleasingByCalculationPath.path =
  putAddcalculationeventNewleasingByCalculationPath.path satisfies string;

const _putAddcalculationeventNewloanByCalculationPathPath = () =>
  defaults.baseUrl + '/addcalculationevent/newloan/:calculationPath';
Object.defineProperty(putAddcalculationeventNewloanByCalculationPath, 'path', {
  get: _putAddcalculationeventNewloanByCalculationPathPath,
  set: _putAddcalculationeventNewloanByCalculationPathPath,
});
/** @path `/addcalculationevent/newloan/:calculationPath` */
putAddcalculationeventNewloanByCalculationPath.path =
  putAddcalculationeventNewloanByCalculationPath.path satisfies string;

const _putAddcalculationeventByCalculationPathExtrarepaymentPath = () =>
  defaults.baseUrl + '/addcalculationevent/:calculationPath/extrarepayment';
Object.defineProperty(
  putAddcalculationeventByCalculationPathExtrarepayment,
  'path',
  {
    get: _putAddcalculationeventByCalculationPathExtrarepaymentPath,
    set: _putAddcalculationeventByCalculationPathExtrarepaymentPath,
  },
);
/** @path `/addcalculationevent/:calculationPath/extrarepayment` */
putAddcalculationeventByCalculationPathExtrarepayment.path =
  putAddcalculationeventByCalculationPathExtrarepayment.path satisfies string;

const _putAddcalculationeventByCalculationPathFullredemptionPath = () =>
  defaults.baseUrl + '/addcalculationevent/:calculationPath/fullredemption';
Object.defineProperty(
  putAddcalculationeventByCalculationPathFullredemption,
  'path',
  {
    get: _putAddcalculationeventByCalculationPathFullredemptionPath,
    set: _putAddcalculationeventByCalculationPathFullredemptionPath,
  },
);
/** @path `/addcalculationevent/:calculationPath/fullredemption` */
putAddcalculationeventByCalculationPathFullredemption.path =
  putAddcalculationeventByCalculationPathFullredemption.path satisfies string;

const _putCreatenewloancalculationpathPath = () =>
  defaults.baseUrl + '/createnewloancalculationpath';
Object.defineProperty(putCreatenewloancalculationpath, 'path', {
  get: _putCreatenewloancalculationpathPath,
  set: _putCreatenewloancalculationpathPath,
});
/** @path `/createnewloancalculationpath` */
putCreatenewloancalculationpath.path =
  putCreatenewloancalculationpath.path satisfies string;

const _putCreatestandalonecalculationpathPath = () =>
  defaults.baseUrl + '/createstandalonecalculationpath';
Object.defineProperty(putCreatestandalonecalculationpath, 'path', {
  get: _putCreatestandalonecalculationpathPath,
  set: _putCreatestandalonecalculationpathPath,
});
/** @path `/createstandalonecalculationpath` */
putCreatestandalonecalculationpath.path =
  putCreatestandalonecalculationpath.path satisfies string;

const _getPaymentnoticeGetpaymentnoticeforprocessingPath = () =>
  defaults.baseUrl + '/paymentnotice/getpaymentnoticeforprocessing';
Object.defineProperty(getPaymentnoticeGetpaymentnoticeforprocessing, 'path', {
  get: _getPaymentnoticeGetpaymentnoticeforprocessingPath,
  set: _getPaymentnoticeGetpaymentnoticeforprocessingPath,
});
/** @path `/paymentnotice/getpaymentnoticeforprocessing` */
getPaymentnoticeGetpaymentnoticeforprocessing.path =
  getPaymentnoticeGetpaymentnoticeforprocessing.path satisfies string;

const _getPaymentnoticeGetpaymentnoticePath = () =>
  defaults.baseUrl + '/paymentnotice/getpaymentnotice';
Object.defineProperty(getPaymentnoticeGetpaymentnotice, 'path', {
  get: _getPaymentnoticeGetpaymentnoticePath,
  set: _getPaymentnoticeGetpaymentnoticePath,
});
/** @path `/paymentnotice/getpaymentnotice` */
getPaymentnoticeGetpaymentnotice.path =
  getPaymentnoticeGetpaymentnotice.path satisfies string;

const _postPaymentnoticePostupdatenoticePath = () =>
  defaults.baseUrl + '/paymentnotice/postupdatenotice';
Object.defineProperty(postPaymentnoticePostupdatenotice, 'path', {
  get: _postPaymentnoticePostupdatenoticePath,
  set: _postPaymentnoticePostupdatenoticePath,
});
/** @path `/paymentnotice/postupdatenotice` */
postPaymentnoticePostupdatenotice.path =
  postPaymentnoticePostupdatenotice.path satisfies string;

// }}}
// #endregion
