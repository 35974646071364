import { useEffect, useState } from 'react';
import { formatDateTime } from '@kk/shared/utils/date';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { NotificationType } from '#ui/constants';
import { useTheme } from '#ui/hooks/useTheme.js';
import clsx from 'clsx';
import { LinkButton } from '../Button';
import { Icon, IconType } from '../Icon';
import {
  removeToast,
  showToast,
  ToastNotificationProps,
} from './ToastProvider';

const theme: Record<NotificationType, string> = {
  default: clsx('bg-neutral-5'),
  info: clsx('bg-blue-60'),
  success: clsx('bg-green-100'),
  warning: clsx('bg-orange-60'),
  error: clsx('bg-red-100'),
};

const icon: Record<NotificationType, IconType | undefined> = {
  default: undefined,
  info: 'info',
  success: 'check-circle',
  warning: 'alert-triangle',
  error: 'alert-diamond',
};

const iconColor: Record<NotificationType, string> = {
  default: '',
  info: clsx('text-white'),
  success: clsx('text-white'),
  warning: clsx('text-orange-110'),
  error: clsx('text-white'),
};

export function useToast() {
  return { showToast, removeToast };
}

export function ToastElement({
  toast,
  toastKey,
}: {
  toast: ToastNotificationProps;
  toastKey: string;
}) {
  const [animated, setIsAnimated] = useState(false);
  const [closed, setClosed] = useState(false);
  const { headline, message, type, size, link, date } = toast;
  const isKunde = useTheme() === 'kunde';
  const closable = toast.closable ?? (type === 'error' || link !== undefined);

  function handleRemoveToast(toastKey: string) {
    const element = document.getElementById(toastKey);

    if (element && element.getAnimations().length === 0) {
      const removeAnimation = element.animate(
        [{ opacity: 1 }, { opacity: 0 }],
        {
          duration: 400,
          easing: 'cubic-bezier(0.6, 0, 0.4, 1)',
          fill: 'forwards',
          delay: 100,
        },
      );
      removeAnimation.onfinish = () => {
        removeToast(toastKey);
      };
    }
  }

  useEffect(() => {
    if (animated) return;
    setIsAnimated(true);
    const element = document.getElementById(toastKey);
    if (!element) return;

    const list = document.querySelector('[data-toast-list]');
    const height = element.offsetHeight;

    if (list !== null) {
      list.animate(
        [
          { transform: `translateY(${height}px)` },
          { transform: 'translateY(0px)' },
        ],
        {
          duration: 160,
          easing: 'cubic-bezier(0.6, 0, 0.4, 1)',
          fill: 'forwards',
        },
      );
    }
    if (closable === false) {
      if (closed) return;
      setTimeout(
        () => {
          setClosed(true);
        },
        toast.size === 'long' ? 3200 : 2000,
      );
    }
  }, [
    closed,
    toastKey,
    animated,
    closable,
    toast.link?.href,
    toast.size,
    toast.type,
  ]);

  const { key, ...toastProps } = toast;

  return (
    <ToastPrimitive.Root
      data-testid={`toast-${toastKey}`}
      id={toastKey}
      className={clsx(
        'transition-opacity ease-[cubic-bezier(0.6,0,0.4,1)]',
        closed ? 'opacity-0 duration-[400ms]' : 'opacity-100',
      )}
      onTransitionEnd={() => {
        removeToast(toastKey);
      }}
      open={true}
      duration={0}
      {...toastProps}
      type="background"
    >
      <div
        className={clsx(
          'w-78 select-text p-2 text-xs',
          type === 'warning' ? '' : 'text-white',
          'laane:rounded',
          'kunde:shadow-md kunde:rounded-md',
          theme[type],
          { 'kunde:min-w-[420px]': size === 'long' },
        )}
      >
        <div className="flex w-full flex-row">
          <Icon
            type={icon[type]}
            className={clsx(iconColor[type])}
            size={isKunde ? 'medium' : 'small'}
            solid
          />
          <div className="flex w-full flex-row justify-between pl-2">
            <div className="space-y-1">
              <ToastPrimitive.Title className="kunde:text-sm font-semibold">
                {headline}
              </ToastPrimitive.Title>

              <ToastPrimitive.Description asChild>
                <div className="flex flex-col font-normal">
                  {size === 'long' || message ? (
                    <span className="pb-1">{message}</span>
                  ) : null}
                  {link ? (
                    <LinkButton
                      padding="6px"
                      className={clsx(
                        type === 'warning'
                          ? '!text-black underline'
                          : '!text-white underline',
                      )}
                      {...link}
                    />
                  ) : null}
                  {size === 'long' && date ? (
                    <time className="text-2xs pt-4">
                      {formatDateTime(date)}
                    </time>
                  ) : null}
                </div>
              </ToastPrimitive.Description>
            </div>
            <div>
              {closable ? (
                <ToastPrimitive.Close
                  aria-label="Luk"
                  onClick={() => handleRemoveToast(toastKey)}
                >
                  <Icon
                    type="cross"
                    size={isKunde ? 'small' : 'tiny'}
                    className="kunde:m-2"
                  />
                </ToastPrimitive.Close>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </ToastPrimitive.Root>
  );
}
