import { lazy, Suspense } from 'react';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { ToastProvider } from '@kk/ui/components/Notifications/ToastProvider';
import i18n from '@kk/ui/i18n';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import NProgress from 'nprogress';
import { I18nProvider } from 'react-aria';
import { useHotkeys } from 'react-hotkeys-hook';
import { I18nextProvider } from 'react-i18next';
import { useSnapshot } from 'valtio';
import msalInstance from '@/api/auth/msalInstance';
import queryClient from '@/api/queryClient';
import { appInsightsPlugin } from '@/app-insights';
import { ErrorBoundary } from '@/components/ErrorBoundary/ErrorBoundary';
import { defaultNS } from '@/locales/resources';
import LoginPage from '@/routes/LoginPage';
import state, { toggleDevtools } from '@/state';

NProgress.configure({ showSpinner: false });
const Router = lazy(() => import('./Router'));
const AppInfo = lazy(() => import('./devtools/AppInfo'));

// lazy load the devtools in production
const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/production').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

function App() {
  const { devtoolsEnabled } = useSnapshot(state);

  // toggle devtools with ctrl+shift+f1 when on production, for debugging purposes
  useHotkeys('ctrl+shift+f1', toggleDevtools);

  return (
    <I18nProvider locale="da-DK">
      <AppInsightsContext.Provider value={appInsightsPlugin}>
        <ErrorBoundary inline={false}>
          <I18nextProvider i18n={i18n} defaultNS={defaultNS}>
            <MsalProvider instance={msalInstance}>
              <UnauthenticatedTemplate>
                <LoginPage />
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <QueryClientProvider client={queryClient}>
                  {devtoolsEnabled ? (
                    <Suspense fallback={null}>
                      {import.meta.env.DEV && (
                        <ReactQueryDevtools initialIsOpen={false} />
                      )}
                      {import.meta.env.PROD && <ReactQueryDevtoolsProduction />}
                      <AppInfo />
                    </Suspense>
                  ) : null}
                  <ToastProvider>
                    <Router />
                  </ToastProvider>
                </QueryClientProvider>
              </AuthenticatedTemplate>
            </MsalProvider>
          </I18nextProvider>
        </ErrorBoundary>
      </AppInsightsContext.Provider>
    </I18nProvider>
  );
}

export default App;
