/**
 * Search Api
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from '@oazapfts/runtime';
import * as QS from '@oazapfts/runtime/query';
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
  headers: {},
  baseUrl: '/',
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type LoanDocument = {
  id?: string | null;
  contractNumber: string | null;
  companyLegalName: string | null;
  companyId: string | null;
  companyNumber?: string | null;
  cvr?: string | null;
  startDate?: string;
  maturityDate?: string;
  principalAmountLCY?: number;
  principalAmountCCY?: string | null;
  notionalAmountLCY?: number;
  loanType: string | null;
  isGreenLoan?: boolean;
  loanStatus: string | null;
};
export type CompanyDocument = {
  id: string | null;
  primaryContactId?: string | null;
  primaryContactName?: string | null;
  companyResponsibleId?: string | null;
  companyResponsibleName?: string | null;
  legalName: string | null;
  companyNumber?: string | null;
  cvr?: string | null;
  expiryDateKYC?: string | null;
  status?: string | null;
  companyLASId?: string | null;
};
export type CaseDocument = {
  caseId: string | null;
  primaryPartyName?: string | null;
  primaryPartyCVR?: string | null;
  primaryPartyCrmGuid?: string | null;
  primaryPartyContactName?: string | null;
  contractId?: string | null;
  title?: string | null;
  createDate?: string | null;
  closeDate?: string | null;
  updatedDate?: string | null;
  caseHandlerName?: string | null;
  eventDescription?: string | null;
  caseStateDescription?: string | null;
  fileClassCode?: string | null;
  casePhaseCode?: string | null;
  executingOrgUnitCode?: string | null;
  closeReasonCode?: string | null;
  closeReasonDescription?: string | null;
};
export type SearchResponse = {
  loanCount?: number | null;
  companyCount?: number | null;
  caseCount?: number | null;
  loans?: LoanDocument[] | null;
  companies?: CompanyDocument[] | null;
  cases?: CaseDocument[] | null;
};
export type ValidationErrorResponse = {
  validationErrors?: string[] | null;
};
export type ErrorResponse = {
  errorMessage?: string[] | null;
};
export type LoanSuggest = {
  companyLegalName?: string | null;
  suggestion?: string | null;
};
export type CaseSuggest = {
  suggestion?: string | null;
};
export type CompanySuggest = {
  legalName?: string | null;
  suggestion?: string | null;
};
export type SuggestResponse = {
  loanSuggests?: LoanSuggest[] | null;
  caseSuggests?: CaseSuggest[] | null;
  companySuggests?: CompanySuggest[] | null;
};
/**
 * <b>query</b> string <i>required</i> :<br /> will do a wildcard search for the search term. <br />  <b>limit</b> integer <i>optional</i>:<br /> The numbers of items to return<br/>default is 1000<br/> <b>offset</b> integer <i>optional</i> :<br /> The index of the first result to return. Use with limit to get the next page of search results.<br/>default is 0
 */
export function search(
  {
    query,
    offset,
    limit,
  }: {
    query?: string;
    offset?: number;
    limit?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: SearchResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/${QS.query(
        QS.explode({
          query,
          offset,
          limit,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * <b>query</b> string <i>required</i> :<br /> will do a wildcard search for the search term. <br />
 */
export function getSuggest(
  {
    query,
  }: {
    query?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: SearchResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/suggest${QS.query(
        QS.explode({
          query,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * <b>query</b> string <i>required</i> :<br /> will do a suggest search for the search term. <br />  <b>highlight</b> string <i>optional</i> :<br /> Highlight the matching part of the sugggestion result string by enclosing it in html bold tags <br/>default is false <br> Accepted input to indicate highlight is enabled "true", "y", "yes"
 */
export function getAutocomplete(
  {
    query,
    highlight,
  }: {
    query?: string;
    highlight?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: SuggestResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/autocomplete${QS.query(
        QS.explode({
          query,
          highlight,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Returns role information
 */
export function getRoles(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: {
        controller?: string;
        roles?: string;
        endpoint?: string;
        httpMethods?: string;
      };
    }>('/_roles', {
      ...opts,
    }),
  );
}
/**
 * Returns health information
 */
export function getHealth(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: {
        status?: string;
        TotalDuration?: string;
        entries?: object;
      };
    }>('/_health', {
      ...opts,
    }),
  );
}

// #region INJECTED PATHS
/* The generated output is not a direct result of oazapfts' default behavior.
 * It instead incorporates our custom code, which adds path placeholder properties for MSW mocking, into the output of oazapfts. */
// {{{

const _searchPath = () => defaults.baseUrl + '/';
Object.defineProperty(search, 'path', { get: _searchPath, set: _searchPath });
/** @path `/` */
search.path = search.path satisfies string;

const _getSuggestPath = () => defaults.baseUrl + '/suggest';
Object.defineProperty(getSuggest, 'path', {
  get: _getSuggestPath,
  set: _getSuggestPath,
});
/** @path `/suggest` */
getSuggest.path = getSuggest.path satisfies string;

const _getAutocompletePath = () => defaults.baseUrl + '/autocomplete';
Object.defineProperty(getAutocomplete, 'path', {
  get: _getAutocompletePath,
  set: _getAutocompletePath,
});
/** @path `/autocomplete` */
getAutocomplete.path = getAutocomplete.path satisfies string;

const _getRolesPath = () => defaults.baseUrl + '/_roles';
Object.defineProperty(getRoles, 'path', {
  get: _getRolesPath,
  set: _getRolesPath,
});
/** @path `/_roles` */
getRoles.path = getRoles.path satisfies string;

const _getHealthPath = () => defaults.baseUrl + '/_health';
Object.defineProperty(getHealth, 'path', {
  get: _getHealthPath,
  set: _getHealthPath,
});
/** @path `/_health` */
getHealth.path = getHealth.path satisfies string;

// }}}
// #endregion
