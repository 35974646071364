export const ROLES = {
  coworker: 'Laanemedarbejder.Access',
  finance: 'Finansmedarbejder.Access',
  backoffice: 'Backofficemedarbejder.Access',
  readOnly: 'Read-only.Access',
  projectParticipant: 'Projektdeltager.Access',
} as const;

export const roles = Object.values(ROLES);

export type Role = (typeof roles)[number];

export const DEPARTMENTS = [
  /* Finans */
  'FIN',
  /* Finans - finansiel bæredygtighed */
  'FIN_BAER',
  /* Finans - funding */
  'FIN_FUN',
  /* Finans - investering */
  'FIN_INV',
  /* Kommunikation - Udlån og leasing */
  'KUN',
  /* Kommunikation - Udlån front */
  'KUN_LAAN',
  /* Kommunikation - Leasing */
  'KUN_LEAS',
  /* Økonomi */
  'OKO',
  /* Økonomi - Backoffice */
  'OKO_BAC',
  /* Økonomi - Regnskab */
  'OKO_RGN',
] as const;

export type Department = (typeof DEPARTMENTS)[number];

/** matches: "FIN", "FIN_BAER", "FIN_FUN", "FIN_INV" */
export function isFinanceDepartment(
  department: Department,
): department is 'FIN' | 'FIN_BAER' | 'FIN_FUN' | 'FIN_INV' {
  return department.startsWith('FIN');
}

/** matches: "OKO", "OKO_BAC", "OKO_RGN" */
export function isEconomyDepartment(
  department: Department,
): department is 'OKO' | 'OKO_BAC' | 'OKO_RGN' {
  return department.startsWith('OKO');
}

/** matches: "KUN", "KUN_LAAN", "KUN_LEAS" */
export function isCommunicationDepartment(
  department: Department,
): department is 'KUN' | 'KUN_LAAN' | 'KUN_LEAS' {
  return department.startsWith('KUN');
}

export const departments: Record<Department, Department> =
  Object.fromEntries<Department>(
    DEPARTMENTS.map<[Department, Department]>((d) => [d, d]),
  ) as Record<Department, Department>;

export const CASE_SCOPES = {
  /** Opret sag */
  createCase: 'create-case',
  /** Hent sag */
  getCase: 'get-case',
  /** Hent aktive sager */
  getActiveCases: 'get-active-cases',
  /** Hent aktive sager lite */
  getActiveCasesLite: 'get-active-cases-lite',
  /** Hent sager for kontrakt nummer */
  getContractCases: 'get-contract-cases',
  /** Hent sager på kontakt */
  getContactCases: 'get-contact-cases',
  /** Hent sager på kunde */
  getCompanyCases: 'get-company-cases',
  /** Hent sags typer */
  getCaseEventDescriptions: 'get-case-event-descriptions',
  /** Hent afslutningsårsager */
  getCaseCloseReasons: 'get-case-close-reasons',
  /** Send tillæg til låneaftale */
  sendAddendum: 'send-addendum',
  /** Send beregning */
  sendCalculation: 'send-calculation',
  /* Luk sag */
  closeCase: 'close-case',
  /* Overtag sag */
  takeOverCase: 'take-over-case',
  /* Opdater kontaktperson */
  updateContact: 'update-contact',
  /* Rul tilbage til beregningsfasen */
  restartCalculationPhase: 'restart-calculation-phase',
  /* Start eksekveringsfasen */
  startExecutionPhase: 'start-execution-phase',
  startExecutionPhaseWithCompensationAmount:
    'start-execution-phase-with-compensation-amount',
  /* Opret note */
  createNote: 'create-note',
  /* Send til finans */
  notifyFinance: 'notify-finance',
  /* Afvis prissætning */
  rejectPricing: 'reject-pricing',
  /* Gennemfør indfrielse */
  completeRedemption: 'complete-redemption',
  /* Gennemfør indfrielse */
  sendAddendumToAgreement: 'send-addendum-to-agreement',
  /* Hent faseansvarstildelinger */
  getPhaseAssignees: 'get-phase-assignees',
  /** Lav ny udlånssag */
  createContractDraft: 'create-contract-draft',
  /** Igangsæt KYC */
  initiateKyc: 'initiate-kyc',
  /** skift fase status */
  changePhaseState: 'change-phase-state',
  /** Hent saginformation statuser */
  caseInformationStates: 'case-information-states',
  /** Opdater en sags fuldmagtshavere  */
  updateCaseMandates: 'update-case-mandates',
} as const;

export const COMPANY_SCOPES = {
  /** Hent relationer */
  getRelations: 'get-relations',
  /** Hent kunde */
  getCompany: 'get-company',
  /** Hent kontakter for kunde */
  getCompanyContacts: 'get-company-contacts',
  /** Hent alle kontakter i CRM */
  getAllContacts: 'get-all-contacts',
  /** Hent én kontakt */
  getContact: 'get-contact',
  /** Hent alle aftaler for en kunde */
  getLoans: 'get-loans',
  /** Hent lån til oprettelse af nye sager */
  getEligibleLoans: 'get-eligible-loans',
  /** Hent bevillinger til oprettelse af nye lån */
  getAllocations: 'get-allocations',
  /** Hent én bevilling */
  getAllocation: 'get-allocation',
  /** Hent én bevillingssag */
  getAllocationCase: 'get-allocation-case',
  /** Hent garantistillere */
  getGuarantors: 'get-guarantors',
  /** opdater garantistillere */
  updateGuarantorDetails: 'update-guarantor-details',
  /** Hent garantistillere */
  getGuarantorDetails: 'get-guarantor-details',
} as const;

export const LOAN_SCOPES = {
  /** Hent aftaledata */
  getLoanInfo: 'get-loan-info',
  /** Hent aftaledata for kreditter */
  getCreditInfo: 'get-credit-info',
  /** Hent alle lån for en kunde */
  getLoanPortfolio: 'get-loan-portfolio',
  /** Hent gyldige betalingsdatoer */
  getPartialRedemptionDates: 'get-partial-redemption-dates',
  /** Hent første interessepayment */
  getFirstInterestPaymentDates: 'get-first-interest-payment-dates',
  /** Lav beregningsgrundlag */
  createCalculationBasis: 'create-calculation-basis',
  /** Lav beregning */
  createCalculation: 'create-calculation',
  /** Opdater beregning */
  updateCalculation: 'update-calculation',
  /** Hent beregning */
  getCalculation: 'get-calculation',
  /** Valider beregning */
  validateCalculation: 'validate-calculation',
  /** Slet beregning */
  deleteCalculation: 'delete-calculation',
  /** Eksporter beregning */
  exportCalculation: 'export-calculation',
  /** Eksporter portefølje */
  exportLoanPortfolio: 'export-loan-portfolio',
  /** Hent EAN information */
  getEanInformation: 'get-ean-information',
  /** Opdater lånekladde */
  updateContractDraft: 'update-contract-draft',
  /** Hent lånekladde */
  getContractDraft: 'get-contract-draft',
  /** Opdater bevilling */
  updateAllocation: 'update-allocation',
} as const;

export const DOCUMENT_SCOPES = {
  /** Dan email med vedhæftet beregning */
  createCalculationEmail: 'create-calculation-email',
  /** Ommærkning af dokument */
  labelDocument: 'label-document',
  /** Hent dokument */
  getDocument: 'get-document',
} as const;

export const ALL_SCOPES = {
  ...CASE_SCOPES,
  ...COMPANY_SCOPES,
  ...LOAN_SCOPES,
  ...DOCUMENT_SCOPES,
} as const;

export const allPermissions = Object.values(ALL_SCOPES);

export type Scope = (typeof allPermissions)[number];

export const readOnlyPermissions = allPermissions.filter(
  // exclude scopes that are not read-only
  (scope: (typeof allPermissions)[number]) =>
    (
      [
        CASE_SCOPES.createCase,
        CASE_SCOPES.getCaseEventDescriptions,
        CASE_SCOPES.getCaseCloseReasons,
        CASE_SCOPES.closeCase,
        CASE_SCOPES.takeOverCase,
        CASE_SCOPES.startExecutionPhase,
        LOAN_SCOPES.getLoanInfo,
        LOAN_SCOPES.getLoanPortfolio,
        LOAN_SCOPES.createCalculationBasis,
        LOAN_SCOPES.createCalculation,
        LOAN_SCOPES.updateCalculation,
        LOAN_SCOPES.deleteCalculation,
        LOAN_SCOPES.validateCalculation,
        CASE_SCOPES.sendAddendum,
        CASE_SCOPES.sendCalculation,
        CASE_SCOPES.updateContact,
        CASE_SCOPES.restartCalculationPhase,
        CASE_SCOPES.createNote,
        CASE_SCOPES.rejectPricing,
        CASE_SCOPES.notifyFinance,
        CASE_SCOPES.completeRedemption,
        CASE_SCOPES.startExecutionPhase,
        CASE_SCOPES.startExecutionPhaseWithCompensationAmount,
        CASE_SCOPES.takeOverCase,
        CASE_SCOPES.createContractDraft,
        CASE_SCOPES.changePhaseState,
        DOCUMENT_SCOPES.createCalculationEmail,
        DOCUMENT_SCOPES.labelDocument,
        COMPANY_SCOPES.updateGuarantorDetails,
      ] satisfies Partial<typeof allPermissions>
    ).includes(scope) === false,
);

export const financeOnlyPermissions = [
  CASE_SCOPES.rejectPricing,
  CASE_SCOPES.startExecutionPhaseWithCompensationAmount,
];
export const financePermissions = [
  ...readOnlyPermissions,
  ...financeOnlyPermissions,
];

export const coworkerPermissions = allPermissions.filter(
  // exclude scopes that are not available to coworkers
  (permission) => !financeOnlyPermissions.includes(permission),
);

export const PERMISSIONS = {
  [ROLES.coworker]: coworkerPermissions,
  [ROLES.finance]: financePermissions,
  [ROLES.readOnly]: readOnlyPermissions,
  [ROLES.projectParticipant]: allPermissions,
  [ROLES.backoffice]: readOnlyPermissions,
};

if (
  import.meta.env.DEV &&
  process.env.NODE_ENV !== 'test' &&
  import.meta.env.MODE !== 'mock'
) {
  function reduceScopePermissionsToTableRows(scopes: object, api: string) {
    return Object.values(scopes).reduce(
      (acc, scope) => [
        ...acc,
        {
          api,
          scope,
          ...Object.values(ROLES).reduce(
            (obj, role) => ({
              ...obj,
              [role]: PERMISSIONS[role].includes(scope) ? '✅' : '⛔',
            }),
            {},
          ),
        },
      ],
      [],
    );
  }

  // 📋 used for easy comparison to the reference table of scopes and roles
  // eslint-disable-next-line no-console
  console.groupCollapsed('Roles & Permissions');
  // eslint-disable-next-line no-console
  console.table([
    ...reduceScopePermissionsToTableRows(CASE_SCOPES, 'Case API'),
    ...reduceScopePermissionsToTableRows(COMPANY_SCOPES, 'Company API'),
    ...reduceScopePermissionsToTableRows(LOAN_SCOPES, 'Loan API'),
    ...reduceScopePermissionsToTableRows(DOCUMENT_SCOPES, 'Document API'),
  ]);
  // eslint-disable-next-line no-console
  console.groupEnd();
}
