import Decimal from 'decimal.js';

const defaultCurrency = 'DKK';

const currencyFormatter = new Intl.NumberFormat('da-DK', {
  currency: defaultCurrency,
  minimumFractionDigits: 2,
});

type FormatCurrencyOptions = {
  appendCurrency?: boolean | string | null;
  showZero?: boolean;
};
/**
 * Formats a numeric value as a currency string.
 *
 * @param {number|undefined} value - The numeric value to format as currency. Optional and defaults to undefined.
 * @param {FormatCurrencyOptions|undefined} options - Optional options object.
 * @returns {string|null} The formatted currency string, or null if the input value is falsy.
 */
export const formatCurrency = (
  value?: number | null,
  options?: FormatCurrencyOptions,
): string =>
  (value === 0 && options?.showZero) || value
    ? currencyFormatter.format(value) +
      (options?.appendCurrency
        ? typeof options.appendCurrency === 'string'
          ? ' ' + options.appendCurrency
          : ' ' + defaultCurrency
        : '')
    : '—';

/**
 * Clamps a number between a minimum and maximum value.
 *
 * @param {number} num - The number to be clamped.
 * @param {number} min - The minimum value to clamp to.
 * @param {number} max - The maximum value to clamp to.
 * @returns {number} The clamped number.
 */
export const clamp = (num: number, min: number, max: number): number => {
  if (isNaN(num) || isNaN(min) || isNaN(max)) {
    return NaN;
  }
  return num < min ? min : num > max ? max : num;
};

/**
 * Formats a percentage amount into Danish format.
 * @param {number} percentage - The percentage to format (e.g. 50 for 50%).
 * @returns {string} The formatted percentage string.
 */
export function formatPercentage(
  percentage: number | null | undefined,
  digits = 2,
  removeUnit?: boolean,
): string {
  if (percentage === undefined || percentage === null || isNaN(percentage))
    return '—';
  const formatted = new Intl.NumberFormat('da-DK', {
    style: 'percent',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(percentage / 100);
  return removeUnit ? formatted.replace('%', '') : formatted;
}

/**
 * Formats a number into basis points format.
 * @param {number} value - The number to format (e.g. 0,0027 for 27 bp).
 * @param {number} digits - Number of decimal places to display (default 0).
 * @param {boolean} removeUnit - Whether to remove the 'bp' unit suffix.
 * @returns {string} The formatted basis points string.
 */
export function formatBasisPoints(
  value: number | null | undefined,
  digits = 0,
  removeUnit = false,
): string {
  if (value === undefined || value === null || isNaN(value)) return '—';
  const formatted = new Intl.NumberFormat('da-DK', {
    maximumFractionDigits: digits,
  }).format(value * 100 * 100);
  return removeUnit ? formatted : `${formatted} bp`;
}

/**
 * Formats a numeric value as a currency string.
 *
 * @param {number|undefined} value - The numeric value to format. Optional and defaults to undefined.
 * @param {'short' | 'long' | undefined} compactDisplay - Whether to format 'short' or 'long'. Optional and defaults to 'short'.
 * @returns {string|null} The compact formatted string.
 */
export function formatCompact(
  value?: number,
  compactDisplay?: 'short' | 'long' | undefined,
): string {
  if (!value || isNaN(value)) return '—';
  return new Intl.NumberFormat('da-DK', {
    notation: 'compact',
    compactDisplay: compactDisplay ?? 'short',
  }).format(value);
}

/**
 * Formats the record size value from bytes to a human-readable format.
 * @param {number} recordSize - The size of the record in bytes.
 * @returns {string} The formatted record size with the appropriate unit.
 */
export function formatFileSize(recordSize?: number | null | undefined): string {
  const sizeUnits: string[] = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  if (recordSize === undefined || recordSize === null || isNaN(recordSize))
    return '—';
  let size: number = recordSize;
  let unitIndex: number = 0;

  while (size >= 1024 && unitIndex < sizeUnits.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  const formattedSize: string = `${size.toFixed(2)} ${sizeUnits[unitIndex]}`;
  return formattedSize;
}

export function isAmountWithinVarianceRange({
  targetAmount,
  referenceAmount,
  variancePercentage,
}: {
  targetAmount?: number;
  referenceAmount?: number;
  variancePercentage?: number;
}): boolean {
  if (!variancePercentage) return targetAmount === referenceAmount;
  if (
    targetAmount === undefined ||
    targetAmount === null ||
    referenceAmount === undefined ||
    referenceAmount === null ||
    isNaN(targetAmount) ||
    isNaN(referenceAmount) ||
    isNaN(variancePercentage)
  )
    return false;

  const variance = (Math.abs(referenceAmount) / 100) * variancePercentage;
  return (
    targetAmount < referenceAmount + variance &&
    targetAmount > referenceAmount - variance
  );
}

/**
 * Formats a numeric value as an abbreviated value string as required for the Progress Bars in Engagement Overview.
 *
 * @param {number} value - The numeric value to format as currency. E.g.: 16000000 => 16 mio. / 1500 => 1,5 t
 * @param {FormatCurrencyOptions|undefined} options - Optional options object.
 * @returns {string} The formatted currency string.
 */
export function formatLargeNumber(number): string {
  if (number >= 1000) {
    const formatter = new Intl.NumberFormat('da', {
      notation: 'compact',
      compactDisplay: 'short',
      maximumSignificantDigits: 3,
    });
    return formatter.format(number);
  } else {
    return number.toLocaleString('da-DK');
  }
}

/**
 * Calculates the sum of decimal values in an array, handling potential null or undefined values.
 *
 * @param {number[]} values - An array of numeric values to be summed.
 * @returns {number} The total sum of the values, converted to a standard number.
 *
 * @example
 * // Returns 10
 * sumDecimalValues([3, 4, 3]);
 *
 * @example
 * // Returns 7 (null and undefined values are treated as 0)
 * sumDecimalValues([3, null, 4, undefined]);
 */
export function sumDecimalValues(
  values: (number | null | undefined)[],
): number {
  return values
    .reduce((total, value) => total.plus(value || 0), new Decimal(0))
    .toNumber();
}
