/**
 * Company Api
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from '@oazapfts/runtime';
import * as QS from '@oazapfts/runtime/query';
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
  headers: {},
  baseUrl: '/',
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type CompanyContactModel = {
  id?: string | null;
  fullname?: string | null;
  firstName?: string | null;
  lastname?: string | null;
  jobTitle?: string | null;
  email?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  pepStatus?: string | null;
};
export type CompanyContactModelRead = {
  id?: string | null;
  fullname?: string | null;
  firstName?: string | null;
  lastname?: string | null;
  jobTitle?: string | null;
  email?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  displayPhone?: string | null;
  pepStatus?: string | null;
};
export type CompanyResponsibleModel = {
  id?: string | null;
  fullname?: string | null;
  jobTitle?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  employeePicture?: any | null;
};
export type CompanyResponsibleModelRead = {
  id?: string | null;
  fullname?: string | null;
  jobTitle?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  displayPhone?: string | null;
  employeePicture?: any | null;
};
export type CompanyModel = {
  companyId?: string;
  companyResponsibleId?: string;
  companyNumber?: string | null;
  cvr?: string | null;
  legalName?: string | null;
  companyLASId?: string | null;
  payerName?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
  status?: any | null;
  kycApproved?: boolean | null;
  kycExpiration?: string | null;
  accountingPeriod?: number | null;
  typeOfLiability?: string | null;
  accountingPurposes?: string | null;
  preferredPaymentMethod?: string | null;
  industryCode?: string | null;
  industryNaceCode?: string | null;
  companyType?: string | null;
  companyCategory?: string | null;
  auditor?: string | null;
  auditorContact?: string | null;
  auditorEmail?: string | null;
  authorizationToForward?: string | null;
  dateOfLatestArticlesOfAssociation?: string | null;
  powerToBindTheCompany?: string | null;
  segmentation?: string | null;
  seNumber?: string | null;
  isGreenland?: boolean;
  primaryContact?: CompanyContactModel | null;
  companyResponsible?: CompanyResponsibleModel | null;
};
export type CompanyModelRead = {
  companyId?: string;
  companyResponsibleId?: string;
  companyNumber?: string | null;
  cvr?: string | null;
  legalName?: string | null;
  companyLASId?: string | null;
  payerName?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
  status?: any | null;
  kycApproved?: boolean | null;
  kycExpiration?: string | null;
  accountingPeriod?: number | null;
  typeOfLiability?: string | null;
  accountingPurposes?: string | null;
  preferredPaymentMethod?: string | null;
  industryCode?: string | null;
  industryNaceCode?: string | null;
  companyType?: string | null;
  companyCategory?: string | null;
  auditor?: string | null;
  auditorContact?: string | null;
  auditorEmail?: string | null;
  authorizationToForward?: string | null;
  dateOfLatestArticlesOfAssociation?: string | null;
  powerToBindTheCompany?: string | null;
  segmentation?: string | null;
  seNumber?: string | null;
  isGreenland?: boolean;
  primaryContact?: CompanyContactModelRead | null;
  companyResponsible?: CompanyResponsibleModelRead | null;
};
export type ValidationErrorResponse = {
  validationErrors?: string[] | null;
};
export type NotFoundResult = {
  statusCode?: number;
};
export type ErrorResponse = {
  errorMessage?: string[] | null;
};
export type ContactRelationModel = {
  name?: string | null;
  accountId?: string | null;
};
export type ContactRelationModelRead = {
  name?: string | null;
  accountId?: string | null;
  roleName?: string | null;
};
export type KycCaseModel = {
  kycId?: string;
  name?: string | null;
  kycStatus?: string | null;
  created?: string | null;
  updated?: string | null;
  ownersAuthenticatedOn?: string | null;
  kycExpiryDate?: string | null;
};
/**
 * Get a company using CompanyId (CRM Guid)
 */
export function getCompanyById(id: string, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CompanyModelRead;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 404;
          data: NotFoundResult;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(`/company/${encodeURIComponent(id)}`, {
      ...opts,
    }),
  );
}
/**
 * Get a company by CVR number
 */
export function getCompanyCvrByCvr(cvr: number, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CompanyModelRead;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 404;
          data: NotFoundResult;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(`/company/cvr/${encodeURIComponent(cvr)}`, {
      ...opts,
    }),
  );
}
/**
 * Get all active companies from CRM defined as approved companies of type "kommune", "region", "Kunde med garantistillelse" or "Kunde med medlemshæftelse". Also potential customers is included, controlled by query parameter 'includePotential'
 */
export function getCompanies(
  {
    includePotential,
  }: {
    includePotential?: boolean;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CompanyModelRead[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/companies${QS.query(
        QS.explode({
          includePotential,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Get a list of companies a contact is related to and the role association using Contactid
 */
export function getContactrelationByContactId(
  contactId: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: ContactRelationModelRead[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(`/contactrelation/${encodeURIComponent(contactId)}`, {
      ...opts,
    }),
  );
}
/**
 * Get all company contacts from CRM, this return all contacts both active and inactive
 */
export function getContacts(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CompanyContactModelRead[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >('/contacts', {
      ...opts,
    }),
  );
}
/**
 * Get a KYC case contact using Kyc guid
 */
export function getKyccaseById(id: string, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: KycCaseModel;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 404;
          data: NotFoundResult;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(`/kyccase/${encodeURIComponent(id)}`, {
      ...opts,
    }),
  );
}
/**
 * Get a company contact using Contactid, this return contact whether it is active or not
 */
export function getContactsById(id: string, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CompanyContactModelRead;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 404;
          data: NotFoundResult;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(`/contacts/${encodeURIComponent(id)}`, {
      ...opts,
    }),
  );
}
/**
 * Get all active contacts including external relations for a company using CompanyId (CRM Guid). Both Company, Relation and contact must be active for contact to be included
 */
export function getCompanyByIdContacts(
  id: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CompanyContactModelRead[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(`/company/${encodeURIComponent(id)}/contacts`, {
      ...opts,
    }),
  );
}
/**
 * Initiate KYC flow in CRM for company with the given Id (CRM Guid). Returns Kyc Id. The prvCreatekk_kycsag privilege is required to initiate the KYC-flow in CRM.
 */
export function postCompanyByIdInitiatekyc(
  id: string,
  contractId: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/company/${encodeURIComponent(id)}/initiatekyc${QS.query(
        QS.explode({
          contractId,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
/**
 * Returns role information
 */
export function getRoles(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: {
        controller?: string;
        roles?: string;
        endpoint?: string;
        httpMethods?: string;
      };
    }>('/_roles', {
      ...opts,
    }),
  );
}
/**
 * Returns health information
 */
export function getHealth(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: {
        status?: string;
        TotalDuration?: string;
        entries?: object;
      };
    }>('/_health', {
      ...opts,
    }),
  );
}

// #region INJECTED PATHS
/* The generated output is not a direct result of oazapfts' default behavior.
 * It instead incorporates our custom code, which adds path placeholder properties for MSW mocking, into the output of oazapfts. */
// {{{

const _getCompanyByIdPath = () => defaults.baseUrl + '/company/:id';
Object.defineProperty(getCompanyById, 'path', {
  get: _getCompanyByIdPath,
  set: _getCompanyByIdPath,
});
/** @path `/company/:id` */
getCompanyById.path = getCompanyById.path satisfies string;

const _getCompanyCvrByCvrPath = () => defaults.baseUrl + '/company/cvr/:cvr';
Object.defineProperty(getCompanyCvrByCvr, 'path', {
  get: _getCompanyCvrByCvrPath,
  set: _getCompanyCvrByCvrPath,
});
/** @path `/company/cvr/:cvr` */
getCompanyCvrByCvr.path = getCompanyCvrByCvr.path satisfies string;

const _getCompaniesPath = () => defaults.baseUrl + '/companies';
Object.defineProperty(getCompanies, 'path', {
  get: _getCompaniesPath,
  set: _getCompaniesPath,
});
/** @path `/companies` */
getCompanies.path = getCompanies.path satisfies string;

const _getContactrelationByContactIdPath = () =>
  defaults.baseUrl + '/contactrelation/:contactId';
Object.defineProperty(getContactrelationByContactId, 'path', {
  get: _getContactrelationByContactIdPath,
  set: _getContactrelationByContactIdPath,
});
/** @path `/contactrelation/:contactId` */
getContactrelationByContactId.path =
  getContactrelationByContactId.path satisfies string;

const _getContactsPath = () => defaults.baseUrl + '/contacts';
Object.defineProperty(getContacts, 'path', {
  get: _getContactsPath,
  set: _getContactsPath,
});
/** @path `/contacts` */
getContacts.path = getContacts.path satisfies string;

const _getKyccaseByIdPath = () => defaults.baseUrl + '/kyccase/:id';
Object.defineProperty(getKyccaseById, 'path', {
  get: _getKyccaseByIdPath,
  set: _getKyccaseByIdPath,
});
/** @path `/kyccase/:id` */
getKyccaseById.path = getKyccaseById.path satisfies string;

const _getContactsByIdPath = () => defaults.baseUrl + '/contacts/:id';
Object.defineProperty(getContactsById, 'path', {
  get: _getContactsByIdPath,
  set: _getContactsByIdPath,
});
/** @path `/contacts/:id` */
getContactsById.path = getContactsById.path satisfies string;

const _getCompanyByIdContactsPath = () =>
  defaults.baseUrl + '/company/:id/contacts';
Object.defineProperty(getCompanyByIdContacts, 'path', {
  get: _getCompanyByIdContactsPath,
  set: _getCompanyByIdContactsPath,
});
/** @path `/company/:id/contacts` */
getCompanyByIdContacts.path = getCompanyByIdContacts.path satisfies string;

const _postCompanyByIdInitiatekycPath = () =>
  defaults.baseUrl + '/company/:id/initiatekyc';
Object.defineProperty(postCompanyByIdInitiatekyc, 'path', {
  get: _postCompanyByIdInitiatekycPath,
  set: _postCompanyByIdInitiatekycPath,
});
/** @path `/company/:id/initiatekyc` */
postCompanyByIdInitiatekyc.path =
  postCompanyByIdInitiatekyc.path satisfies string;

const _getRolesPath = () => defaults.baseUrl + '/_roles';
Object.defineProperty(getRoles, 'path', {
  get: _getRolesPath,
  set: _getRolesPath,
});
/** @path `/_roles` */
getRoles.path = getRoles.path satisfies string;

const _getHealthPath = () => defaults.baseUrl + '/_health';
Object.defineProperty(getHealth, 'path', {
  get: _getHealthPath,
  set: _getHealthPath,
});
/** @path `/_health` */
getHealth.path = getHealth.path satisfies string;

// }}}
// #endregion
