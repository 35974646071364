export type BaseButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPress?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function BaseButton(props: BaseButtonProps) {
  const {
    children,
    type = 'button',
    className = 'bg-none text-inherit border-none bg-transparent p-0 cursor-pointer focus-outline',
    onPress,
    ...rest
  } = props;
  return (
    <button onClick={onPress} {...rest} type={type} className={className}>
      {children}
    </button>
  );
}

export default BaseButton;
